// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  max-width: 500px;
  width: 80vw;
  margin: 100px auto 0;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/components/not-found/not-found.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,oBAAA;EACA,cAAA;AACF","sourcesContent":["img {\r\n  max-width: 500px;\r\n  width: 80vw;\r\n  margin: 100px auto 0;\r\n  display: block;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
