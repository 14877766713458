import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CacheService } from './cache.service';

/**
 * This service contains all functions to call api/Weather
 */
@Injectable({ providedIn: 'root' })
export class WeatherService {
    constructor(
        private http: HttpClient,
        private cache: CacheService,
    ) {}

    /**
   * Get latest weather from our cached version of the weather API
   */
    getWeather(): Observable<unknown> {
    // Compose the endpoint
        const endpoint = environment.api.baseUrl + 'Weather/GetWeather';
        // Create the HTTP request observable
        const observable = this.http.get<object>(endpoint);

        return this.cache.serveFromCacheOr('weather', observable, {
            maxAge: 60
        });
    }
}
