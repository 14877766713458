import {
    Component,
    EventEmitter,
    Output,
    Input
} from '@angular/core';

/**
 * This component shows buttons for pagination
 */
@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
    @Input() currentPage = 0;
    @Input() hasNext = true;
    @Input() hasPrev = true;
    @Input() showDirectPagination = true;
    @Input() nextLabel = 'Volgende';
    @Input() prevLabel = 'Vorige';
    /**
   * When reverse is true, the previous page has a lower page number instead of a higher (default behavious)
   */
    @Input() reverse = false;

    @Output() next = new EventEmitter<number>();
    @Output() prev = new EventEmitter<number>();
    @Output() directPagination = new EventEmitter<number>();

    constructor() {}

    emitNext(): void {
        if (this.reverse) {
            this.currentPage = +this.currentPage + 1;
        } else {
            this.currentPage = +this.currentPage - 1;
        }
        this.next.emit(this.currentPage);
    }

    emitPrev(): void {
        if (this.reverse) {
            this.currentPage = +this.currentPage - 1;
        } else {
            this.currentPage = +this.currentPage + 1;
        }
        this.prev.emit(this.currentPage);
    }

    emitDirectPagination(page: number): void {
        this.directPagination.emit(page);
    }
}
