// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmform {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/confirm-email-reset-password/confirm-email-reset-password.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF","sourcesContent":[".confirmform{\r\n  width: 100%;\r\n  .mat-form-field{\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
