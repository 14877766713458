import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Tag } from '../interfaces/tag.interface';

@Injectable({
    providedIn: 'root',
})
export class TagService {
    constructor(private http: HttpClient) {}

    getTags() {
        const endpoint = `${environment.api.baseUrl}Groups/tags`;
        return this.http.get<Tag[]>(endpoint);
    }
    postTag(value: string) {
        const endpoint = `${environment.api.baseUrl}Groups/tag`;
        return this.http.post<null>(endpoint, { value: value });
    }
    postTagForGroup(groupID: number, tagNormalizedName: string) {
        const endpoint = `${environment.api.baseUrl}Groups/tag/group`;
        return this.http.post<null>(endpoint, {
            groupID: groupID,
            tagNormalizedName: tagNormalizedName,
        });
    }
    deleteTagForGroup(groupID: number, tagNormalizedName: string) {
        const endpoint = `${environment.api.baseUrl}Groups/tag/group/${groupID}/${tagNormalizedName}`;
        return this.http.delete(endpoint);
    }
}
