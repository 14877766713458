import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, switchMap, takeUntil, filter, take } from 'rxjs/operators';
import { GroupMember } from '../interfaces/group-member.interface';
import { environment } from './../../environments/environment';
import { Certificate } from './../interfaces/certificate.interface';
import { UserInfo } from './../interfaces/user-info.interface';
import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { Member } from '../interfaces/member.interface';

/**
 * Service which contains all functions to call api/userinfo
 */
@Injectable({
    providedIn: 'root',
})
export class UserinfoService implements OnDestroy {
    userInfoSubject = new BehaviorSubject<UserInfo>(null);
    userGroupMemberSubject = new BehaviorSubject<GroupMember[]>(null);

    destroySub = new Subject<void>();

    constructor(
        private http: HttpClient,
        private cache: CacheService,
        private auth: AuthService
    ) {
        this.auth.userLoggedIn
            .pipe(
                takeUntil(this.destroySub),
                filter((t) => t),
                switchMap(() => this.getNewUserInfo())
            )
            .subscribe((uI) => {
                this.userInfoSubject.next(uI);
            });
        this.auth.userLoggedIn
            .pipe(
                takeUntil(this.destroySub),
                filter((t) => t),
                switchMap(() => this.getFreshUserGroups())
            )
            .subscribe((uG) => {
                this.userGroupMemberSubject.next(uG);
            });
        this.auth.userLoggedIn
            .pipe(
                takeUntil(this.destroySub),
                filter((t) => !t)
            )
            .subscribe(() => {
                this.userInfoSubject.next(null);
            });
    }
    ngOnDestroy() {
        this.destroySub.next();
        this.userInfoSubject.complete();
        this.destroySub.complete();
    }

    /**
   * TO DO:
   * Integrate with auth.service?
   */

    getUserInfo(): Observable<UserInfo> {
        return this.userInfoSubject.pipe(
            // Once we have user info serve it
            filter((u) => u !== null),
            take(1)
        );
    }
    getNewUserInfo() {
        const endpoint = environment.api.baseUrl + 'UserInfo';
        // Construct the observable we want the data from
        return this.http.get<UserInfo>(endpoint);
    }
    getCachedUserInfo() {
    // Build the API endpoint URL
        const endpoint = environment.api.baseUrl + 'UserInfo';
        // Construct the observable we want the data from
        const observable = this.http.get<UserInfo>(endpoint);
        // Now use the cache service and try to serve the data from the cache
        // For when it's not in cache yet/anymore, we send the observable that
        // we want to be executed and options for how to save the data in cache
        return this.cache.serveFromCacheOr('user-info', observable, {
            // We add 'sensitive' so the data gets deleted when the user signs out
            tags: ['sensitive'],
            maxAge: 60 * 60,
        });
    }
    getMemberInfo() {
        return this.http.get<Member>(environment.api.baseUrl + 'members/myaccount');
    }

    hasRight(right: string): Observable<boolean> {
        return this.getUserInfo().pipe(map((info) => info.rights.includes(right)));
    }

    getUserID(): Observable<number> {
        return this.getUserInfo().pipe(map((info) => info.memberID));
    }
    getFreshUserGroups() {
        const endpoint = environment.api.baseUrl + 'UserInfo/Groups';
        return this.http
            .get(endpoint)
            .pipe(map((g: { groupMembers: GroupMember[] }) => g.groupMembers));
    }
    getUserGroups(): Observable<GroupMember[]> {
        return this.userGroupMemberSubject.pipe(
            filter((f) => f != null),
            take(1)
        );
    }
    isUserInGroup(groupID: number): Observable<boolean> {
        return this.getUserGroups().pipe(
            map((g: GroupMember[]) => g.some((h) => h.groupID == groupID))
        );
    }
    getUserCertificates(): Observable<Certificate[]> {
        return this.getUserID().pipe(
            switchMap((userID) => {
                // Build the API endpoint URL
                const endpoint =
          environment.api.baseUrl + `Members/${userID}/Certificates`;
                // Construct the observable we want the data from
                const observable = this.http.get<Certificate[]>(endpoint);
                // Now use the cache service and try to serve the data from the cache
                // For when it's not in cache yet/anymore, we send the observable that
                // we want to be executed and options for how to save the data in cache
                return this.cache.serveFromCacheOr(
                    'user-info-certificates',
                    observable,
                    {
                        // We add 'sensitive' so the data gets deleted when the user signs out
                        tags: ['sensitive'],
                        maxAge: 60 * 60,
                    }
                );
            })
        );
    }
}
