import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, switchMap, tap } from "rxjs";
import { WeatherService } from 'src/app/services/weather.service';

@Component({
    selector: 'app-weather',
    templateUrl: './weather.component.html',
    styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit {
    weather$: Observable<unknown>;
    temperature$: Observable<string>;
    @Input() type: string;
    @Output() succeeded = new EventEmitter<boolean>();

    constructor(
        private weatherService: WeatherService
    ) {
        this.succeeded.emit(false);

        this.weather$ = this.weatherService.getWeather().pipe(
            tap(() => {
                this.succeeded.emit(true);
            })
        );
    }

    ngOnInit() {
        this.temperature$ = this.weather$.pipe(
            switchMap(async (x) => {
                let temperature = x["current"]["temp"];
                if (temperature) {
                    temperature -= 273.15;
                    return (Math.round(temperature * 10) / 10).toFixed(1) + "°C";
                }
                return "";
            }));
    }
}