import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MemberDocument } from '../interfaces/document.interface';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(private http: HttpClient) {}

    getMyDocuments(): Observable<MemberDocument[]> {
        const endpoint = environment.api.baseUrl + 'Documents';

        return this.http.get<MemberDocument[]>(endpoint);
    }

    postDocument({
        file,
        fileName,
    }: {
        file: File;
        fileName: string;
    }): Observable<MemberDocument> {
        const endpoint = environment.api.baseUrl + 'Documents';
        const form = new FormData();
        form.append('file', file, file.name);
        form.append('FileName', fileName);

        return this.http.post<MemberDocument>(endpoint, form);
    }

    deleteDocument(doc : MemberDocument){
        const id = doc.memberDocumentID;
        const endpoint = environment.api.baseUrl + 'Documents/' + id;  // even kijken of het niet string zijn problemen geeft
        return this.http.delete(endpoint);
    }
}
