import { CertificatesService } from './../../../services/certificates.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { delay, filter, switchMap } from 'rxjs/operators';
import { Member } from 'src/app/interfaces/member.interface';
import { Type } from 'src/app/interfaces/type.interface';
import { AccountService } from '../../../services/account.service';
import { MembersService } from '../../../services/members.service';
import { TypesService } from '../../../services/types.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { Certificate } from 'src/app/interfaces/certificate.interface';


/**
 * This component shows a form to add a member
 */
@Component({
    selector: 'app-add-member',
    templateUrl: './add-member.component.html',
    styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {
    member: Member;
    sexTypes: Type[];
    memberStatuses: Type[];
    possibleCerts: Certificate[];

    constructor(
        private membersService: MembersService,
        private typesService: TypesService,
        private accountService: AccountService,
        private certificatesService: CertificatesService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
    ) {
    // Update view with given values
        this.certificatesService.getCertificates().subscribe(c => {
            this.possibleCerts = c;
        });
    }

    ngOnInit() {
        this.member = {
            firstName: '',
            infix: '',
            lastName: '',
            birthdate: '',
            telephoneNumber: '',
            sendEmail: false,
            receiveEmailsPreference: "all",
            iban: ''
        };
        // Load sexTypes for form
        this.typesService.getTypes('MemberSex').subscribe(
            (types: Type[]) => {
                this.sexTypes = types;
                this.member.sexID = types[0].typeID;
            },
            error => {
                console.log(error);
            }
        );

        // Load memberStatus for form
        this.typesService.getTypes('MemberStatus').subscribe(
            (types: Type[]) => {
                this.memberStatuses = types;
                this.member.memberStatusID = types[0].typeID;
            },
            error => {
                console.log(error);
            }
        );
    }

    // Add member to api
    addMemberClicked() {
        // console.log(this.member.firstName ,
        //     this.member.lastName,
        //     this.member.initials ,
        //     this.member.email,
        //     this.member.telephoneNumber ,
        //     this.member.birthdate ,
        //     this.member.memberStatusID ,
        //     this.member.memberSince,
        //     this.member.parentCity,
        //     this.member.parentHouseNumber ,
        //     this.member.parentPostalCode,
        //     this.member.parentStreet,
        //     this.member.study,
        //     this.member.studentNumber,
        //     this.member.sexID,
        //     this.member.iban)
    // TODO: convert this to reactive form
        if (
            this.member.firstName &&
            this.member.lastName &&
            this.member.initials &&
            this.member.email &&
            this.member.telephoneNumber &&
            this.member.birthdate &&
            this.member.memberStatusID &&
            this.member.memberSince &&
            this.member.parentCity &&
            this.member.parentHouseNumber &&
            this.member.parentPostalCode &&
            this.member.parentStreet &&
            this.member.study &&
            this.member.studentNumber && // hij staat nu 0 of 00000 niet toe.
            this.member.sexID &&
            this.member.iban
        ) {
            this.member.iban=this.member.iban.split(' ').join(''); // met replace werkt het niet
            this.membersService.postMember(this.member).subscribe(
                () => {
                    this.snackBar.open('Succesvol toegevoegd', 'OK', { duration: 3000 });
                    this.sendActivationMail();
                },
                error => {
                    console.log(error);
                    this.snackBar.open('Er ging iets mis. De IBAN of Email zou ongeldig kunnen zijn.', 'OK', { duration: 3000 });
                }
            );
        } else {
            this.snackBar.open('Alle velden met een * zijn verplicht', 'OK', {
                duration: 3000
            });
        }
    }

    sendActivationMail() {
        const modalRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Wil je een activatiemail sturen?',
                body: `Wil je het nieuwe lid meteen een activatiemail sturen, zodat er een account wordt aangemaakt op het ledenportaal?`,
            },
        });
        modalRef
            .afterClosed()
            .pipe(
                filter((result) => result),
                switchMap(() =>
                    this.accountService.resendConfirmations(
                        [this.member]
                    )
                )
            )
            .subscribe(() => {
                this.snackBar.open('Mail verstuurd', 'OK', { duration: 3000 });
            });
    }


}
