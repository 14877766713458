import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from '../services/cache.service';
import { AuthService } from '../services/auth.service';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SignedOutGuard  {
    constructor(
        public auth: AuthService,
        public router: Router,
        private cache: CacheService
    ) {}

    async canActivate() {
        const isAuth = await this.auth.userLoggedIn.pipe(take(1)).toPromise();
        if (isAuth) {
            this.auth.signOut();
            return true;
        }
        return true;
    }
}
