import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Member } from 'src/app/interfaces/member.interface';
import { TypesService } from 'src/app/services/types.service';
import { Type } from 'src/app/interfaces/type.interface';

@Component({
    selector: 'app-status-dialog',
    templateUrl: './status-dialog.component.html',
    styleUrls: ['./status-dialog.component.scss']
})
export class StatusDialogComponent implements OnInit {
    selection: Array<Member>;
    statusTypes: Type[];
    selected: Type;
    constructor(
        public typesService: TypesService,
        public dialogRef: MatDialogRef<StatusDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { selection: Array<Member> }
    ) {
    // Update view with given values
        this.selection = data.selection;
    }
    ngOnInit(): void {
        this.typesService.getTypes('MemberStatus').subscribe((res: Type[]) => {
            this.statusTypes = res;
        });
    }

    onConfirm(): void {
    // Close the dialog, return true
        this.dialogRef.close(this.selected);
    }

    onDismiss(): void {
    // Close the dialog, return false
        this.dialogRef.close(false);
    }
}
