import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-confirm-email-reset-password',
    templateUrl: './confirm-email-reset-password.component.html',
    styleUrls: ['./confirm-email-reset-password.component.scss']
})
export class ConfirmEmailResetPasswordComponent implements OnInit {
    goal: 'confirm' | 'reset' = 'confirm';
    email = '';
    gotEmail = false;
    password = '';
    password2 = '';
    token = '';
    gotToken = false;
    errorMessage: string = undefined;

    constructor(
        private accountService: AccountService,
        private router: Router,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        if (this.route.routeConfig.path === 'reset-password') {
            this.goal = 'reset';
        }

        this.route.queryParams.subscribe(params => {
            this.email = params.email;
            if (this.email) {
                this.gotEmail = true;
            }
            this.token = params.token;
            if (this.token) {
                this.gotToken = true;
            }
        });
    }

    confirm(): void {
        let post$;
        if (this.goal === 'reset') {
            post$ = this.accountService.postPasswordReset(
                this.token,
                this.email,
                this.password
            );
        } else {
            post$ = this.accountService.postEmailConfirmation(
                this.token,
                this.email,
                this.password
            );
        }
        post$.subscribe(
            () => {
                this.errorMessage = undefined;
                this.token = '';
                this.password = '';
                this.password2 = '';
                this.snackBar.open(
                    'Je kunt nu inloggen met je nieuwe wachtwoord.',
                    'OK',
                    { duration: 3000 }
                );
                this.router.navigate(['/sign-in']);
            },
            error => {
                console.log(error.error);
                this.errorMessage = error.error
                    ? error.error
                    : 'Er ging iets mis. Neem contact op met de webcie via webmaster@orcaroeien.nl';
                this.snackBar.open(error.error, 'OK', { duration: 3000 });
            }
        );
    }
}
