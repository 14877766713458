import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import QrScanner from 'qr-scanner';

/**
 * This service takes a file and uses the QR code library to decode the QR code in the file
 */
@Injectable()
export class QrCodeReaderService {
    decode(file: File): Observable<string> {
        return new Observable(observer => {
            QrScanner.scanImage(file, {})
                .then(scanResult => {
                    // Return the result through an Observable
                    console.log(scanResult);
                    observer.next(scanResult.toString());
                    observer.complete();
                })
                .catch(() => {
                    observer.next("error");
                    observer.complete();
                })
        });
    }
}
