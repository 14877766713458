import { Component, OnInit, ViewChild } from '@angular/core';
import { Member } from 'src/app/interfaces/member.interface';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AccountService } from 'src/app/services/account.service';
import { filter, switchMap } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/modules/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { TypesService } from 'src/app/services/types.service';
import { Type } from 'src/app/interfaces/type.interface';
import { FindMembers } from 'src/app/interfaces/find-members.interface';
import { MembersService } from 'src/app/services/members.service';
import * as moment from 'moment';
import { StatusDialogComponent } from '../status-dialog/status-dialog.component';
import { CertificateDialogComponent } from '../certificate-dialog/certificate-dialog.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-member-control-overview',
    templateUrl: './member-control-overview.component.html',
    styleUrls: ['./member-control-overview.component.scss'],
})
export class MemberControlOverviewComponent implements OnInit {
    dataSource: MatTableDataSource<Member>;
    collumns = [
        'select',
        'firstName',
        'memberStatus',
        'telephoneNumber',
        'email',
        'vegetarian'
    ];
    selection = new SelectionModel<Member>(true, []);
    today = moment().format();
    isAdmin = false;

    @ViewChild(MatSort) sort: MatSort;

    openExtraFilter = true;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    filterForm: UntypedFormGroup;
    memberStatusTypes: Type[];
    memberSexTypes: Type[];
    filterValues = {
        hasAccount: false,
        davilexID: false,
        activated: false,
        vegetarian: false,
        searchString: '',
    };
    accountFilter = new UntypedFormControl(false);
    davilexFilter = new UntypedFormControl(false);
    activatedFilter = new UntypedFormControl(false);
    vegetarianFilter = new UntypedFormControl(false);

    private selectionMembers: Member[];
    constructor(
        private dialog: MatDialog,
        private accountService: AccountService,
        private authService: AuthService,
        private typesService: TypesService,
        private snackBar: MatSnackBar,
        private memberService: MembersService
    ) {
        this.dataSource = new MatTableDataSource<Member>();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (obj, property) =>
            this.getProperty(obj, property);
        this.dataSource.sort = this.sort;
    }

    ngOnInit() {
        this.filterForm = new UntypedFormGroup({
            memberStatus: new UntypedFormControl([]),
            dateFrom: new UntypedFormControl(null),
            dateTo: new UntypedFormControl(null),
            memberSexs: new UntypedFormControl([]),
            hasAccount: new UntypedFormControl(false),
            notActivated: new UntypedFormControl(false),
            noDavilex: new UntypedFormControl(false),
            isVegetarian: new UntypedFormControl(false)
        });
        this.isAdmin = this.authService.hasRole('Admin');
        forkJoin(
            this.typesService.getTypes('MemberStatus'),
            this.typesService.getTypes('MemberSex')
        ).subscribe(
            ([status, sex]) => {
                this.memberSexTypes = sex;
                this.memberStatusTypes = status;
                const selected = status.find(
                    (e) =>
                        e.normalizedName === 'actieflid' || e.normalizedName === 'afroeilid'
                );
                this.filterForm.patchValue({ memberStatus: [selected] });
                this.findMembers();
            },
            (error) => {
                console.log(error);
            }
        );
    }
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.selectionMembers.length;
        return numSelected === numRows;
    }
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.selectionMembers.forEach((row) => this.selection.select(row));
    }
    public applyBoolFilter(column: string) {
        this.filterValues[column] = !this.filterValues[column];
        console.log(this.filterValues);
        this.dataSource.filter = JSON.stringify(this.filterValues);
    }
    public applyFilter(val: string) {
        this.filterValues.searchString = val.toLowerCase();

        this.dataSource.filter = JSON.stringify(this.filterValues);
    }
    resendConfirmationMail() {
        const modalRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Weet je het zeker?',
                body: `Weet je zeker dat je deze gebruikers een mail wilt sturen?
        Let op: geselecteerde gebruikers die hun mail reeds geactiveerd hebben ontvangen geen mail`,
            },
        });
        modalRef
            .afterClosed()
            .pipe(
                filter((result) => result),
                switchMap(() =>
                    this.accountService.resendConfirmations(this.selection.selected)
                )
            )
            .subscribe(() => {
                this.snackBar.open('Mail verstuurd', 'OK', { duration: 3000 });
            });
    }
    changeStatus() {
        const modalRef = this.dialog.open(StatusDialogComponent, {
            data: {
                selection: this.selection.selected,
            },
        });

        modalRef
            .afterClosed()
            .pipe(
                filter((result) => result),
                switchMap((result) =>
                    this.memberService.changeMultipleStatus(
                        this.selection.selected,
                        result
                    )
                )
            )
            .subscribe(() => {
                this.snackBar.open('Status Veranderd', 'OK', { duration: 3000 });
                this.findMembers();
            });
    }
    giveCertificates() {
        const _modalRef = this.dialog.open(CertificateDialogComponent, {
            data: {
                selection: this.selection.selected,
            },
        });
    }
    getProperty = (obj, path) => path.split('.').reduce((o, p) => o && o[p], obj);

    generatePassword() {
        if (this.selection.selected.length == 1) {
            this.accountService
                .getNewPassword(this.selection.selected[0].memberID)
                .subscribe((result: { password: string; user: string }) => {
                    this.dialog.open(ConfirmDialogComponent, {
                        data: {
                            body: `Nieuw wachtwoord gegenereerd met naam: ${result.user} en wachtwoord: ${result.password}`,
                        },
                    });
                });
        }
    }
    findMembers() {
        this.selection.clear();
        const resp = <FindMembers>this.filterForm.value;

        this.memberService.findMembers(resp).subscribe(
            (val: Member[]) => {
                this.dataSource = new MatTableDataSource<Member>(val);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.dataSource.filterPredicate = (data: Member, filters: string) => {
                    const parsedFilters = JSON.parse(filters);

                    return (
                        (parsedFilters.hasAccount ? data.hasAccount : true) &&
            (parsedFilters.davilexID ? !data.davilexID : true) &&
            (parsedFilters.activated ? !data.activated : true) &&
            (parsedFilters.vegetarian ? data.vegetarian : true) &&
            ([data.firstName, data.infix, data.lastName]
                .join(' ')
                .toLowerCase()
                .includes(parsedFilters.searchString) ||
              [data.firstName, data.lastName]
                  .join(' ')
                  .toLowerCase()
                  .includes(parsedFilters.searchString) ||
              data.email.toLowerCase().includes(parsedFilters.searchString))
                    );
                };
                this.applyFilter(this.filterValues.searchString);

                this.selectionMembers = val;
            },
            (error) => {
                console.log(error);
            }
        );
    }
}
