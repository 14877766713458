import { Pipe, PipeTransform } from '@angular/core';

/**
 * Detects Hyves style emoji and maps them to Markdown images
 */
@Pipe({
    name: 'hyves',
})
export class HyvesEmoji implements PipeTransform {
    // This is the list with all possible Emoji
    emoticons: { [shortcut: string]: string } = {
        ':angel:': 'smiley_angel.gif',
        ':angellove:': 'smiley_angellove.gif',
        ':angelmusic:': 'smiley_angelmusic.gif',
        ':attention:': 'smiley_attention.gif',
        ':babe:': 'smiley_babe.gif',
        ':baby:': 'smiley_baby.gif',
        ':bagonhead:': 'smiley_bagonhead.gif',
        ':baguette:': 'smiley_baguette.gif',
        ':banana:': 'smiley_banana.gif',
        ':baseball:': 'smiley_baseball.gif',
        ':bathing:': 'smiley_bathing.gif',
        ':bbq:': 'smiley_bbq.gif',
        ':beachball:': 'smiley_beachball.gif',
        ':beard:': 'smiley_beard.gif',
        ':bee:': 'smiley_bee.gif',
        ':beeing:': 'smiley_beeing.gif',
        ':bellydancer:': 'smiley_bellydancer.gif',
        ':bignews:': 'smiley_bignews.gif',
        ':bike:': 'smiley_bike.gif',
        ':blablabla:': 'smiley_blablabla.gif',
        ':blackberry_pingen:': 'smiley_blackberry_pingen.gif',
        ':blackeye:': 'smiley_blackeye.gif',
        ':blameshift:': 'smiley_blameshift.gif',
        ':blink:': 'smiley_blink.gif',
        ':boogie:': 'smiley_boogie.gif',
        ':boos:': 'smiley_boos.gif',
        ':bored:': 'smiley_bored.gif',
        ':boris:': 'smiley_boris.gif',
        ':boxing:': 'smiley_boxing.gif',
        ':brancard:': 'smiley_brancard.gif',
        ':breakdance:': 'smiley_breakdance.gif',
        ':bril:': 'smiley_bril.gif',
        ':bro:': 'smiley_bro.gif',
        ':brushing:': 'smiley_brushing.gif',
        ':bunny2:': 'smiley_bunny2.gif',
        ':butterfly:': 'smiley_butterfly.gif',
        ':byebye:': 'smiley_byebye.gif',
        ':carnaval:': 'smiley_carnaval.gif',
        ':cat:': 'smiley_cat.gif',
        ':cheer:': 'smiley_cheer.gif',
        ':cheering:': 'smiley_cheering.gif',
        ':cheerleader:': 'smiley_cheerleader.gif',
        ':cheers:': 'smiley_cheers.gif',
        ':chess:': 'smiley_chess.gif',
        ':chick:': 'smiley_chick.gif',
        ':chinrub:': 'smiley_chinrub.gif',
        ':chitchat:': 'smiley_chitchat.gif',
        ':clap:': 'smiley_clap.gif',
        ':cloudnine:': 'smiley_cloudnine.gif',
        ':clown:': 'smiley_clown.gif',
        ':cocktail:': 'smiley_cocktail.gif',
        ':coffee:': 'smiley_coffee.gif',
        ':coins:': 'smiley_coins.gif',
        ':computerfight:': 'smiley_computerfight.gif',
        ':confused_scheel:': 'smiley_confused_scheel.gif',
        ':confused_vraagteken:': 'smiley_confused_vraagteken.gif',
        ':cook:': 'smiley_cook.gif',
        ':cooking:': 'smiley_cooking.gif',
        ':coolpeace:': 'smiley_coolpeace.gif',
        ':cowboy:': 'smiley_cowboy.gif',
        ':cow:': 'smiley_cow.gif',
        ':crazy:': 'smiley_crazy.gif',
        ':crocodile:': 'smiley_crocodile.gif',
        ':crush:': 'smiley_crush.gif',
        ':cry:': 'smiley_cry.gif',
        ':cupid:': 'smiley_cupid.gif',
        ':dancing:': 'smiley_dancing.gif',
        ':dead:': 'smiley_dead.gif',
        ':deer:': 'smiley_deer.gif',
        ':depressive:': 'smiley_depressive.gif',
        ':devilbabe:': 'smiley_devilbabe.gif',
        ':devil:': 'smiley_devil.gif',
        ':dilemma:': 'smiley_dilemma.gif',
        ':dinner:': 'smiley_dinner.gif',
        ':disappear:': 'smiley_disappear.gif',
        ':disco:': 'smiley_disco.gif',
        ':dj:': 'smiley_dj.gif',
        ':doctor:': 'smiley_doctor.gif',
        ':dog:': 'smiley_dog.gif',
        ':doiknowyou:': 'smiley_doiknowyou.gif',
        ':drawing:': 'smiley_drawing.gif',
        ':drinking:': 'smiley_drinking.gif',
        ':driving:': 'smiley_driving.gif',
        ':duck:': 'smiley_duck.gif',
        ':duh:': 'smiley_duh.gif',
        ':duivels:': 'smiley_duivels.gif',
        ':eatcrocodile:': 'smiley_eatcrocodile.gif',
        ':eatpaper:': 'smiley_eatpaper.gif',
        ':egoboost:': 'smiley_egoboost.gif',
        ':elephant:': 'smiley_elephant.gif',
        ':engagement:': 'smiley_engagement.gif',
        ':eyes:': 'smiley_eyes.gif',
        ':fever:': 'smiley_fever.gif',
        ':firefox:': 'smiley_firefox.gif',
        ':fireworks:': 'smiley_fireworks.gif',
        ':firstdate:': 'smiley_firstdate.gif',
        ':fishing:': 'smiley_fishing.gif',
        ':fitness:': 'smiley_fitness.gif',
        ':flabbergasted:': 'smiley_flabbergasted.gif',
        ':flowers:': 'smiley_flowers.gif',
        ':flying:': 'smiley_flying.gif',
        ':fool:': 'smiley_fool.gif',
        ':freezing:': 'smiley_freezing.gif',
        ':friday:': 'smiley_friday.gif',
        ':frog:': 'smiley_frog.gif',
        ':fruitcake:': 'smiley_fruitcake.gif',
        ':funnyface:': 'smiley_funnyface.gif',
        ':gaming:': 'smiley_gaming.gif',
        ':geheim:': 'smiley_geheim.gif',
        ':geschokt:': 'smiley_geschokt.gif',
        ':getalife:': 'smiley_getalife.gif',
        ':ghost:': 'smiley_ghost.gif',
        ':goaway:': 'smiley_goaway.gif',
        ':goodbye:': 'smiley_goodbye.gif',
        ':graduate:': 'smiley_graduate.gif',
        ':groot:': 'smiley_groot.gif',
        ':groupdance:': 'smiley_groupdance.gif',
        ':grouphug:': 'smiley_grouphug.gif',
        ':guillotine:': 'smiley_guillotine.gif',
        ':gum:': 'smiley_gum.gif',
        ':hammer:': 'smiley_hammer.gif',
        ':hammer2:': 'smiley_hammer2.gif',
        ':handkiss:': 'smiley_handkiss.gif',
        ':hangloose:': 'smiley_hangloose.gif',
        ':happy_birthday:': 'smiley_happy_birthday.gif',
        ':happygroup:': 'smiley_happygroup.gif',
        ':happy_thumbup:': 'smiley_happy_thumbup.gif',
        ':headbang:': 'smiley_headbang.gif',
        ':headphones:': 'smiley_headphones.gif',
        ':heartbounce:': 'smiley_heartbounce.gif',
        ':hearteyes:': 'smiley_hearteyes.gif',
        ':heartkiss:': 'smiley_heartkiss.gif',
        ':hearts:': 'smiley_hearts.gif',
        ':hippie:': 'smiley_hippie.gif',
        ':hitchhike:': 'smiley_hitchhike.gif',
        ':hockey:': 'smiley_hockey.gif',
        ':holdhands:': 'smiley_holdhands.gif',
        ':hollandflag:': 'smiley_hollandflag.gif',
        ':holland:': 'smiley_holland.gif',
        ':hollandschmink:': 'smiley_hollandschmink.gif',
        ':hollandyell:': 'smiley_hollandyell.gif',
        ':horseriding:': 'smiley_horseriding.gif',
        ':hosanna:': 'smiley_hosanna.gif',
        ':hugging:': 'smiley_hugging.gif',
        ':huh:': 'smiley_huh.gif',
        ':humping:': 'smiley_humping.gif',
        ':hungry:': 'smiley_hungry.gif',
        ':icecream:': 'smiley_icecream.gif',
        ':indewar:': 'smiley_indewar.gif',
        ':indian:': 'smiley_indian.gif',
        ':innocent:': 'smiley_innocent.gif',
        ':ironing:': 'smiley_ironing.gif',
        ':johnny:': 'smiley_johnny.gif',
        ':juggling:': 'smiley_juggling.gif',
        ':jumping:': 'smiley_jumping.gif',
        ':kiekeboe:': 'smiley_kiekeboe.gif',
        ':kilian:': 'smiley_kilian.gif',
        ':kissblush:': 'smiley_kissblush.gif',
        ':kisshand:': 'smiley_kisshand.gif',
        ':knight:': 'smiley_knight.gif',
        ':knipoog:': 'smiley_knipoog.gif',
        ':lach:': 'smiley_lach.gif',
        ':lekkerding:': 'smiley_lekkerding.gif',
        ':licking:': 'smiley_licking.gif',
        ':lifting:': 'smiley_lifting.gif',
        ':lips:': 'smiley_lips.gif',
        ':lol:': 'smiley_lol.gif',
        ':lollol:': 'smiley_lollol.gif',
        ':lolonground:': 'smiley_lolonground.gif',
        ':love_brokenheart:': 'smiley_love_brokenheart.gif',
        ':love_heart:': 'smiley_love_heart.gif',
        ':love_kissing:': 'smiley_love_kissing.gif',
        ':lovemail:': 'smiley_lovemail.gif',
        ':loveyou:': 'smiley_loveyou.gif',
        ':machinegun:': 'smiley_machinegun.gif',
        ':mad:': 'smiley_mad.gif',
        ':marry:': 'smiley_marry.gif',
        ':math:': 'smiley_math.gif',
        ':merrychristmas:': 'smiley_merrychristmas.gif',
        ':mickey:': 'smiley_mickey.gif',
        ':middlefinger:': 'smiley_middlefinger.gif',
        ':moon:': 'smiley_moon.gif',
        ':mouse:': 'smiley_mouse.gif',
        ':mouth:': 'smiley_mouth.gif',
        ':music:': 'smiley_music.gif',
        ':nah:': 'smiley_nah.gif',
        ':nerd:': 'smiley_nerd.gif',
        ':neutraal:': 'smiley_neutraal.gif',
        ':newcoffee:': 'smiley_newcoffee.gif',
        ':newspaper:': 'smiley_newspaper.gif',
        ':ninja:': 'smiley_ninja.gif',
        ':nopompom:': 'smiley_nopompom.gif',
        ':olympics:': 'smiley_olympics.gif',
        ':oneglass:': 'smiley_oneglass.gif',
        ':pacman:': 'smiley_pacman.gif',
        ':paint:': 'smiley_paint.gif',
        ':partyanimal:': 'smiley_partyanimal.gif',
        ':party:': 'smiley_party.gif',
        ':party_pompom:': 'smiley_party_pompom.gif',
        ':partypeople:': 'smiley_partypeople.gif',
        ':pasta:': 'smiley_pasta.gif',
        ':peace:': 'smiley_peace.gif',
        ':piano:': 'smiley_piano.gif',
        ':piet:': 'smiley_piet.gif',
        ':pig:': 'smiley_pig.gif',
        ':piggybank:': 'smiley_piggybank.gif',
        ':pingpong:': 'smiley_pingpong.gif',
        ':pinkribbon:': 'smiley_pinkribbon.gif',
        ':pipe:': 'smiley_pipe.gif',
        ':pirate:': 'smiley_pirate.gif',
        ':pizza:': 'smiley_pizza.gif',
        ':playcar:': 'smiley_playcar.gif',
        ':playing:': 'smiley_playing.gif',
        ':please:': 'smiley_please.gif',
        ':police:': 'smiley_police.gif',
        ':popcorn:': 'smiley_popcorn.gif',
        ':protest:': 'smiley_protest.gif',
        ':pushup:': 'smiley_pushup.gif',
        ':rabbit:': 'smiley_rabbit.gif',
        ':raining:': 'smiley_raining.gif',
        ':rap:': 'smiley_rap.gif',
        ':rasta:': 'smiley_rasta.gif',
        ':reading:': 'smiley_reading.gif',
        ':redroses:': 'smiley_redroses.gif',
        ':rolling:': 'smiley_rolling.gif',
        ':romeo:': 'smiley_romeo.gif',
        ':royal:': 'smiley_royal.gif',
        ':runforest:': 'smiley_runforest.gif',
        ':running:': 'smiley_running.gif',
        ':sailing:': 'smiley_sailing.gif',
        ':salesman:': 'smiley_salesman.gif',
        ':samurai:': 'smiley_samurai.gif',
        ':santa:': 'smiley_santa.gif',
        ':sax:': 'smiley_sax.gif',
        ':schade:': 'smiley_schade.gif',
        ':schamen:': 'smiley_schamen.gif',
        ':scooter:': 'smiley_scooter.gif',
        ':sealed:': 'smiley_sealed.gif',
        ':shades:': 'smiley_shades.gif',
        ':shakeit:': 'smiley_shakeit.gif',
        ':shaving:': 'smiley_shaving.gif',
        ':shining:': 'smiley_shining.gif',
        ':shithappens:': 'smiley_shithappens.gif',
        ':shock:': 'smiley_shock.gif',
        ':shoot:': 'smiley_shoot.gif',
        ':shopping:': 'smiley_shopping.gif',
        ':shouting:': 'smiley_shouting.gif',
        ':showmethemoney:': 'smiley_showmethemoney.gif',
        ':shrug:': 'smiley_shrug.gif',
        ':sick:': 'smiley_sick.gif',
        ':sint:': 'smiley_sint.gif',
        ':skater:': 'smiley_skater.gif',
        ':sleeping:': 'smiley_sleeping.gif',
        ':slobber:': 'smiley_slobber.gif',
        ':smoking:': 'smiley_smoking.gif',
        ':snoopy:': 'smiley_snoopy.gif',
        ':snorkling:': 'smiley_snorkling.gif',
        ':soccer:': 'smiley_soccer.gif',
        ':sortof:': 'smiley_sortof.gif',
        ':sos:': 'smiley_sos.gif',
        ':spam:': 'smiley_spam.gif',
        ':spider:': 'smiley_spider.gif',
        ':stupidme:': 'smiley_stupidme.gif',
        ':sun:': 'smiley_sun.gif',
        ':superman2:': 'smiley_superman2.gif',
        ':superman:': 'smiley_superman.gif',
        ':surfer:': 'smiley_surfer.gif',
        ':surprised:': 'smiley_surprised.gif',
        ':swinging:': 'smiley_swinging.gif',
        ':takepicture:': 'smiley_takepicture.gif',
        ':talktothehand:': 'smiley_talktothehand.gif',
        ':taz:': 'smiley_taz.gif',
        ':teddybear:': 'smiley_teddybear.gif',
        ':thebest:': 'smiley_thebest.gif',
        ':thefinger:': 'smiley_thefinger.gif',
        ':thnx:': 'smiley_thnx.gif',
        ':threesisters:': 'smiley_threesisters.gif',
        ':threesome:': 'smiley_threesome.gif',
        ':throwpc:': 'smiley_throwpc.gif',
        ':tomatoes:': 'smiley_tomatoes.gif',
        ':tong2:': 'smiley_tong2.gif',
        ':tongue_fierce:': 'smiley_tongue_fierce.gif',
        ':tongue_fingersear:': 'smiley_tongue_fingersear.gif',
        ':tongue_sleepout:': 'smiley_tongue_sleepout.gif',
        ':tongue_updown:': 'smiley_tongue_updown.gif',
        ':toohot:': 'smiley_toohot.gif',
        ':tooth:': 'smiley_tooth.gif',
        ':tour:': 'smiley_tour.gif',
        ':tramp:': 'smiley_tramp.gif',
        ':turtle:': 'smiley_turtle.gif',
        ':twosome:': 'smiley_twosome.gif',
        ':typing:': 'smiley_typing.gif',
        ':ugotmail:': 'smiley_ugotmail.gif',
        ':verdrietig:': 'smiley_verdrietig.gif',
        ':washing:': 'smiley_washing.gif',
        ':watchingtv:': 'smiley_watchingtv.gif',
        ':wave:': 'smiley_wave.gif',
        ':wc:': 'smiley_wc.gif',
        ':weed:': 'smiley_weed.gif',
        ':wheelchair:': 'smiley_wheelchair.gif',
        ':whistle:': 'smiley_whistle.gif',
        ':winkkiss:': 'smiley_winkkiss.gif',
        ':winnen:': 'smiley_winnen.gif',
        ':wk2010:': 'smiley_wk2010.gif',
        ':woot:': 'smiley_woot.gif',
        ':worship:': 'smiley_worship.gif',
        ':wtf:': 'smiley_wtf.gif',
        ':XD:': 'smiley_XD.gif',
        ':yawn:': 'smiley_yawn.gif',
        ':yeah:': 'smiley_yeah.gif',
        ':zorro:': 'smiley_zorro.gif',
        ':star_yellow.png:': 'star_yellow.png',
    };

    path = '/assets/emoji/';
    regex: RegExp;

    /**
   * Builds the Regular Expression we use to detect Emoji's given the emoticons hashmap.
   */
    constructor() {
    // These characters should be escaped from the match
        const escapeChars = /[[\]{}()*+?.\\|^$\-,&#\s]/g;
        // Get all possible Emoji shortcuts
        const shortcuts = Object.keys(this.emoticons);
        // Make a regex string by replacing the escapeChars, wrapping in brackets
        // and joining with |
        const regexString = shortcuts
            .map((s) => `(${s.replace(escapeChars, '\\$&')})`)
            .join('|');
        // Make a function Regular Expression out of it
        this.regex = new RegExp(regexString, 'g');
    }

    /**
   * Transforms all Emoji shortcuts in a string to markdown images
   */
    transform(value: string): string {
        if (value === null) {
            return;
        }
        // Apply the regex to the provided value
        return value.replace(this.regex, (match) => {
            // For each match that is an existing emoji..
            if (this.emojiExists(match)) {
                // Return the image
                return `![](${this.path + this.emoticons[match]}#hyves-emoji)`;
            }
            return match;
        });
    }

    /**
   * Takes a shortcut and checks whether it exists
   */
    emojiExists(shortcut: string): boolean {
        return typeof this.emoticons[shortcut] !== 'undefined';
    }
}
