// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-list-item {
  margin-bottom: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/member-certificates/member-certificates.component.scss"],"names":[],"mappings":"AAAA,2HAAA;AACA;EACI,kBAAA;AACJ","sourcesContent":["/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */\r\nmat-list-item {\r\n    margin-bottom: 4px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
