import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import {
    Certificate,
    CertificateGrant
} from './../interfaces/certificate.interface';
import { CacheService } from './cache.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CertificatesService {
    constructor(private http: HttpClient, private cache: CacheService) {}

    /**
   * Get all possible certificates
   */
    getCertificates(): Observable<Certificate[]> {
    // Build the API endpoint URL
        const endpoint = environment.api.baseUrl + 'Certificates';
        // Construct the observable we want the data from
        const observable = this.http.get<Certificate[]>(endpoint);
        // Now use the cache service and try to serve the data from the cache
        // For when it's not in cache yet/anymore, we send the observable that
        // we want to be executed and options for how to save the data in cache
        return this.cache.serveFromCacheOr('certificates', observable, {
            maxAge: 7 * 24 * 60 * 60
        });
    }

    /**
   * Get a certificate by its ID
   * @param id
   */
    getCertificateById(id: number): Observable<Certificate> {
    // Build the API endpoint URL
        const endpoint = environment.api.baseUrl + 'Certificates/' + id;
        // Construct the observable we want the data from
        const observable = this.http.get<Certificate>(endpoint);
        // Now use the cache service and try to serve the data from the cache
        // For when it's not in cache yet/anymore, we send the observable that
        // we want to be executed and options for how to save the data in cache
        return this.cache.serveFromCacheOr('certificate-' + id, observable, {
            maxAge: 7 * 24 * 60 * 60
        });
    }
    postMultipleCertificates(certificateGrants: CertificateGrant[]) {
        const endpoint = environment.api.baseUrl + 'members/certificates/multiple';

        return this.http.post(endpoint, certificateGrants);
    }
}
