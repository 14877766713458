// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[bsdatepicker] {
  margin-top: 1px;
}

.mat-mdc-form-field {
  max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/datetime-picker/datetime-picker.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AACA;EACE,gBAAA;AAEF","sourcesContent":["input[bsdatepicker] {\r\n  margin-top: 1px;\r\n}\r\n.mat-mdc-form-field {\r\n  max-width: 300px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
