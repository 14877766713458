// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-row {
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/questions-form/questions-form.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".button-row {\r\n  margin-bottom: 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
