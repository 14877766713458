import { FormattingService } from './../../../../services/formatting.service';
import { DateFormatPipe } from 'ngx-moment';
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import * as moment from 'moment';

/**
 * This component shows a date and time picker
 */
@Component({
    selector: 'app-datetime-picker',
    templateUrl: './datetime-picker.component.html',
    styleUrls: ['./datetime-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {
    date = '';
    time: string;

    @Input()
        startDateTime: Date;

    @Input()
        outputAsString = true;

    @Input()
        labels = ['Datum', 'Tijd'];

    @Input()
        minDate: Date;

    @Output()
        valueChange = new EventEmitter<Date | string>();



    constructor(private formattingService: FormattingService) {}

    ngOnInit() {
        this.time = new DateFormatPipe().transform(
            this.startDateTime,
            'HH:mm'
        );
        this.date = new DateFormatPipe().transform(
            this.startDateTime,
            'YYYY-MM-DD'
        );
    }

    timeChanged(time) {
        if (time) {
            this.time = time;
            this.valueChange.emit(this.format());
        }
    }

    dateChanged(date) {
        if (date) {
            this.date = date;
            this.valueChange.emit(this.format());
        }
    }

    format(): Date | string {
        const time = moment(this.time, 'HH:mm');
        const datetime = moment(this.date).hour(time.hour()).minute(time.minute());

        if (this.outputAsString) {
            return this.formattingService.getApiDateFromDateObject(datetime.toDate());
        } else {
            return datetime.toDate();
        }
    }
}
