import { Component, Input } from '@angular/core';
import { Member } from './../../../interfaces/member.interface';

/**
 * Component shows a member summary
 */
@Component({
    selector: 'app-member-summary',
    templateUrl: './member-summary.component.html',
    styleUrls: ['./member-summary.component.css'],
})
export class MemberSummaryComponent {
    // Takes a member as input
    @Input()
        member: Member;

    /**
   *  Takes extra data as input to set what is shown below the member's name.
   *  Currently: birthdate, telephonenumber or ploeghoofd
   */

    @Input()
        extraData: string;

    @Input()
        showAge?: boolean;

    @Input()
        special?: boolean;

    constructor() {}

    // Calculate age (after birthday)
    getAge(): number {
        if (this.member.birthdate) {
            const newDate = new Date(this.member.birthdate);
            return new Date().getFullYear() - newDate.getFullYear();
        }
    }

    // Return vegetarian string
    isVegetarian(): string {
        return this.member.vegetarian ? '(Vega)' : '';
    }
}
