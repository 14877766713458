import { CacheService } from './../../services/cache.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sign-out',
    styleUrls: ['sign-out.component.scss'],
    templateUrl: 'sign-out.component.html'
})
export class SignOutComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private router: Router,
        private cache: CacheService
    ) {}

    ngOnInit() {
        this.cache.removeAllWithTag('sensitive');
        this.authService.signOut();
        this.router.navigate(['sign-in']);
    }
}
