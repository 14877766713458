import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-btn-icon-ag-renderer',
    templateUrl: './btn-icon-ag-renderer.component.html',
    styleUrls: ['./btn-icon-ag-renderer.component.scss'],
})
export class BtnIconAgRendererComponent
implements ICellRendererAngularComp {
    private params: ICellRendererParams;
    disabled: boolean;
    constructor() { }
    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.disabled = params.context.disabled(params.value);
    }
    btnClickedHandler() {
        this.params.context.clicked(this.params);
    }
    refresh(_params: ICellRendererParams): boolean {
        return true;
    }
}
