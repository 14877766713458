import { Component, Input } from '@angular/core';

/**
 * Stolen from https://codepen.io/thezimmee/pen/aqzQgZ
 * "Better good stolen than bad made up"?
 */
@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
    @Input()
        centered = true;
}
