import { CacheService } from './cache.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Type } from '../interfaces/type.interface';

/**
 * This service contains all functions to call api/types
 */
@Injectable({ providedIn: 'root' })
export class TypesService {
    constructor(private http: HttpClient, private cache: CacheService) {}

    /**
   * Get types information from API based on type string
   * @param type
   */
    getTypes(type: string): Observable<Type[]> {
        const endpoint = environment.api.baseUrl + 'Types/' + type;
        const getTypes$ = this.http.get<Type[]>(endpoint);
        return this.cache.serveFromCacheOr(
            'types-' + type,
            getTypes$,
            // Save types for in cache for 60 minutes
            { maxAge: 60 * 60 }
        );
    }
}
