import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-toggle-btn-ag-renderer',
    templateUrl: './toggle-btn-ag-renderer.component.html',
    styleUrls: ['./toggle-btn-ag-renderer.component.scss'],
})
export class ToggleBtnAgRendererComponent
implements ICellRendererAngularComp {
    private params: ICellRendererParams;
    valueBtn = false;
    constructor() { }
    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.valueBtn = params.value;
    }
    btnClickedHandler() {

        this.params.value = this.valueBtn;
        this.params.context.clicked(this.params);
    }
    // ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    // }
    refresh(params) {
        this.params = params;
        this.valueBtn = params.value;
        return true;
    }
}
