import { FormattingService } from './formatting.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Article, ArticleCategory } from '../interfaces/article.interface';

/**
 * This service contains all functions to call api/comments
 */
@Injectable({ providedIn: 'root' })
export class ArticlesService {
    // All Article fields that contain dates
    dateFields = ['publishDate', 'dateApproved', 'dateCreated', 'lastEdited'];

    constructor(
        private http: HttpClient,
        private formattingService: FormattingService
    ) {}

    getArticles({
        take = 10,
        skip = 0,
        query = '',
        onlyMyPosts = false,
        onlyFrontPage = false,
        onlyDissaproved = false,
        notYetPublished = false,
        catogories = [],
    }: {
        take?: number;
        skip?: number;
        query?: string;
        onlyMyPosts?: boolean;
        onlyFrontPage?: boolean;
        onlyDissaproved?: boolean;
        notYetPublished?: boolean;
        catogories?: string[];
    }): Observable<Article[]> {
        const endpoint = environment.api.baseUrl + 'Articles';

        let params = new HttpParams();

        params = params.append('take', take.toString());
        params = params.append('skip', skip.toString());
        params = params.append('onlyMyPosts', onlyMyPosts.toString());
        params = params.append('onlyFrontPage', onlyFrontPage.toString());
        params = params.append('onlyDissaproved', onlyDissaproved.toString());
        params = params.append('notYetPublished', notYetPublished.toString());

        if (query.length > 0) {
            params = params.append('query', query);
        }

        catogories.forEach((c) => {
            params = params.append('catogories', c);
        });

        return this.http.get<Article[]>(endpoint, { params });
    }

    getArticlesByEvent(id: number) {
        const endpoint = environment.api.baseUrl + 'Articles/events/' + id;
        return this.http.get<Article[]>(endpoint);
    }

    // Post new article
    postArticle(link: string, article: Article): Observable<string> {
        const endpoint = environment.api.baseUrl + 'Articles/' + link;

        return this.http.post<string>(endpoint, article);
    }

    putArticle(link: string, article: Article): Observable<string> {
        const endpoint =
      environment.api.baseUrl + 'Articles/' + article.articleID + '/' + link;

        return this.http.put<string>(endpoint, article);
    }

    getSpecificArticle(slug: string): Observable<Article> {
        const endpoint = environment.api.baseUrl + 'Articles/' + slug;
        return this.http
            .get<Article>(endpoint)
            .pipe(this.formattingService.mapUtcToLocal(this.dateFields));
    }

    deleteSpecificArticle(id: number) {
        const endpoint = environment.api.baseUrl + 'Articles/' + id;
        return this.http.delete(endpoint);
    }

    toggleArticleApproval(id: number) {
        const endpoint = environment.api.baseUrl + 'Articles/' + id + '/approve';
        return this.http.put(endpoint, {});
    }

    voteArticle(id: number, voteType: string) {
        const endpoint = environment.api.baseUrl + 'Articles/vote';

        return this.http.post(endpoint, { articleID: id, voteType: voteType });
    }

    removeVoteArticle(id: number) {
        const endpoint = environment.api.baseUrl + 'Articles/vote/' + id;

        return this.http.delete(endpoint);
    }

    // Categories

    getCategories(alsoArchived: boolean = false) {
        const endpoint = environment.api.baseUrl + 'Articles/categories';

        let params = new HttpParams();
        params = params.append('alsoArchived', alsoArchived.toString());

        return this.http.get<ArticleCategory[]>(endpoint, { params });
    }
    postCategory(cat: ArticleCategory) {
        const endpoint = environment.api.baseUrl + 'Articles/category';
        return this.http.post<ArticleCategory>(endpoint, cat);
    }
    putCategory(cat: ArticleCategory) {
        const endpoint = environment.api.baseUrl + 'Articles/category';
        return this.http.put<ArticleCategory>(endpoint, cat);
    }
    deleteCategory(normName: string) {
        const endpoint = environment.api.baseUrl + 'Articles/category/' + normName;
        return this.http.delete<ArticleCategory>(endpoint);
    }
}
