// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  max-width: 1400px;
  margin: 4rem auto 1rem;
  color: #555;
  font-size: 0.9rem;
  flex-shrink: 0;
}
footer > * {
  margin: 5px 15px;
}

.coat_of_arms {
  padding-top: 25px;
  padding-right: 15px;
}
.coat_of_arms img {
  max-width: 9rem;
}
@media screen and (min-width: 960px) {
  .coat_of_arms {
    padding-top: 0;
    text-align: right;
  }
  .coat_of_arms img {
    max-width: 10rem;
  }
}

.label {
  display: block;
  text-transform: uppercase;
  color: rgb(163, 163, 163);
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

.whitecolor {
  color: rgba(255, 255, 255, 0.6666666667) !important;
}
.whitecolor .label {
  color: rgba(255, 255, 255, 0.8) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,sBAAA;EACA,WAAA;EACA,iBAAA;EACA,cAAA;AACF;AAAE;EACE,gBAAA;AAEJ;;AAEA;EACE,iBAAA;EACA,mBAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAE;EANF;IAOI,cAAA;IACA,iBAAA;EAGF;EAFE;IACE,gBAAA;EAIJ;AACF;;AAAA;EACE,cAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;AAGF;;AADA;EACE,mDAAA;AAIF;AAHE;EACE,0CAAA;AAKJ","sourcesContent":["footer {\r\n  max-width: 1400px;\r\n  margin: 4rem auto 1rem;\r\n  color: #555;\r\n  font-size: .9rem;\r\n  flex-shrink: 0;\r\n  > * {\r\n    margin: 5px 15px;\r\n  }\r\n}\r\n\r\n.coat_of_arms {\r\n  padding-top: 25px;\r\n  padding-right: 15px;\r\n  img {\r\n    max-width: 9rem;\r\n  }\r\n  @media screen and (min-width: 960px) {\r\n    padding-top: 0;\r\n    text-align: right;\r\n    img {\r\n      max-width: 10rem;\r\n    }\r\n  }\r\n}\r\n\r\n.label {\r\n  display: block;\r\n  text-transform: uppercase;\r\n  color: rgb(163, 163, 163);\r\n  font-size: .75rem;\r\n  margin-bottom: .25rem;\r\n}\r\n.whitecolor{\r\n  color: #fffa !important;\r\n  .label{\r\n    color: #fffc !important;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
