// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fab-container {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.fab-container > div {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 5px;
}

.fab-buttons ::ng-deep button {
  margin-bottom: 17px;
}

.fab-toggler {
  float: right;
  z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/fab-menu/fab-menu.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;AACF","sourcesContent":[".fab-container {\r\n  position: fixed;\r\n  bottom: 30px;\r\n  right: 30px;\r\n  z-index: 1000;\r\n  display: flex;\r\n  flex-direction: column-reverse;\r\n  align-items: center;\r\n\r\n  > div {\r\n    display: flex;\r\n    flex-direction: column-reverse;\r\n    align-items: center;\r\n    margin-bottom: 5px;\r\n  }\r\n}\r\n.fab-buttons ::ng-deep button {\r\n  margin-bottom: 17px;\r\n}\r\n\r\n.fab-toggler {\r\n  float: right;\r\n  z-index: 100;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
