// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-vote {
  background-color: #d81e05;
  color: white;
}

::ng-deep .custom-tooltip {
  white-space: pre-line;
}

.article-icons {
  cursor: pointer;
  display: flex;
}
.article-icons img {
  margin-left: -7px;
  min-width: 25px;
  margin-top: 6px;
}
.article-icons .vote-count {
  margin-top: 8px;
  margin-left: 5px;
}

.react {
  width: 100%;
}

.comment-icons {
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 2px;
  background-color: rgb(214, 214, 214);
  padding: 5px;
  border-radius: 10px;
  padding-bottom: 4px;
  padding-left: 10px;
  display: flex;
}
.comment-icons img {
  margin-left: -5px;
}
.comment-icons .vote-count {
  font-size: small;
  margin-left: 5px;
}

.comment-actions {
  display: flex;
}
.comment-actions .action-button {
  color: #d81e05;
}
.comment-actions .dot-seperator {
  background-color: rgb(214, 214, 214);
  border-radius: 4px;
  height: 4px;
  width: 4px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.comment-actions .time {
  font-size: small;
}
.comment-actions .voted {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/reaction/reaction.component.scss"],"names":[],"mappings":"AACA;EACE,yBAAA;EACA,YAAA;AAAF;;AAEA;EACE,qBAAA;AACF;;AACA;EACE,eAAA;EACA,aAAA;AAEF;AADE;EACE,iBAAA;EACA,eAAA;EACA,eAAA;AAGJ;AAAE;EACE,eAAA;EACA,gBAAA;AAEJ;;AACA;EACE,WAAA;AAEF;;AAAA;EACE,eAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,oCAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;AAGF;AAFE;EACE,iBAAA;AAIJ;AADE;EACE,gBAAA;EACA,gBAAA;AAGJ;;AAAA;EACE,aAAA;AAGF;AAFE;EACE,cAAA;AAIJ;AAFE;EACE,oCAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAIJ;AAFE;EACE,gBAAA;AAIJ;AAFE;EACE,iBAAA;AAIJ","sourcesContent":["@import \"./../../../../../palette.scss\";\r\n.my-vote {\r\n  background-color: orca-color(\"primary\");\r\n  color: white;\r\n}\r\n::ng-deep .custom-tooltip {\r\n  white-space: pre-line;\r\n}\r\n.article-icons {\r\n  cursor: pointer;\r\n  display: flex;\r\n  img {\r\n    margin-left: -7px;\r\n    min-width: 25px;\r\n    margin-top: 6px;\r\n  }\r\n\r\n  .vote-count {\r\n    margin-top: 8px;\r\n    margin-left: 5px;\r\n  }\r\n}\r\n.react {\r\n  width: 100%;\r\n}\r\n.comment-icons {\r\n  cursor: pointer;\r\n  position: absolute;\r\n  right: 10px;\r\n  bottom: 2px;\r\n  background-color: orca-color(\"accent-tone\");\r\n  padding: 5px;\r\n  border-radius: 10px;\r\n  padding-bottom: 4px;\r\n  padding-left: 10px;\r\n  display: flex;\r\n  img {\r\n    margin-left: -5px;\r\n  }\r\n\r\n  .vote-count {\r\n    font-size: small;\r\n    margin-left: 5px;\r\n  }\r\n}\r\n.comment-actions {\r\n  display: flex;\r\n  .action-button {\r\n    color: orca-color(\"primary\");\r\n  }\r\n  .dot-seperator {\r\n    background-color: orca-color(\"accent-tone\");\r\n    border-radius: 4px;\r\n    height: 4px;\r\n    width: 4px;\r\n    margin-top: 10px;\r\n    margin-left: 10px;\r\n    margin-right: 10px;\r\n  }\r\n  .time {\r\n    font-size: small;\r\n  }\r\n  .voted {\r\n    font-weight: bold;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
