// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-option-img {
  vertical-align: middle;
  margin-right: 3px;
  border-radius: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/members/small-member-search/small-member-search.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,iBAAA;EACA,mBAAA;AACF","sourcesContent":[".example-option-img {\r\n  vertical-align: middle;\r\n  margin-right: 3px;\r\n  border-radius: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
