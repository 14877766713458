// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card {
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/add-tag/add-tag.component.scss"],"names":[],"mappings":"AACA,2HAAA;AACA;EACE,cAAA;AAAF","sourcesContent":["\r\n/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */\r\nmat-card {\r\n  margin: 10px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
