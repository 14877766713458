import { MatSnackBar } from '@angular/material/snack-bar';
import { CacheService } from './../../services/cache.service';
import { switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from './../../services/account.service';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';

@Component({
    selector: 'app-reset-email',
    templateUrl: './reset-email.component.html',
    styleUrls: ['./reset-email.component.css']
})
export class ResetEmailComponent implements OnInit {
    userId: string;
    gotUserId = false;
    token: string;
    gotToken = false;

    constructor(
        private accountService: AccountService,
        private router: Router,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar,
        private cacheService: CacheService
    ) {}

    ngOnInit() {
        this.route.queryParams
            .pipe(
                switchMap(params =>
                    params.userID && params.token
                        ? this.accountService.postEmailReset(params.token, params.userID)
                        : of(null)
                )
            )
            .subscribe(
                result => {
                    if (result) {
                        this.snackBar.open(
                            'Je inlognaam en email zijn veranderd. Je kan nu inloggen.',
                            'OK',
                            { duration: 5000 }
                        );
                        this.cacheService.removeAllWithTag('member');
                        this.router.navigateByUrl('/');
                    } else {
                        this.snackBar.open('Incorrecte URL', 'OK', { duration: 3000 });
                    }
                },
                errors => {
                    console.log(errors);
                    this.snackBar.open(errors.error, 'OK', { duration: 3000 });
                }
            );
    }
}
