import { MemberNameComponent } from './member-name/member-name.component';
import { SearchMemberComponent } from './search-member/search-member.component';
import { AddMemberComponent } from './add-member/add-member.component';
import { MemberBirthdayComponent } from './member-birthdays/member-birthdays.component';
import { SharedModule } from './../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MemberSummaryComponent } from './member-summary/member-summary.component';
import { MemberPictureComponent } from './member-picture/member-picture.component';
import { MemberAdmincontrolComponent } from './member-admincontrol/member-admincontrol.component';
import { MemberControlOverviewComponent } from './member-admincontrol/member-control-overview/member-control-overview.component';
import { UploadMembersComponent } from './member-admincontrol/upload-members/upload-members.component';
import { StatusDialogComponent } from './member-admincontrol/status-dialog/status-dialog.component';
import { CertificateDialogComponent } from './member-admincontrol/certificate-dialog/certificate-dialog.component';
import { SmallMemberSearchComponent } from './small-member-search/small-member-search.component';

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule.forChild([]), SharedModule],
    declarations: [
        MemberSummaryComponent,
        SearchMemberComponent,
        SmallMemberSearchComponent,
        MemberNameComponent,
        MemberPictureComponent,
        AddMemberComponent,
        MemberBirthdayComponent,
        MemberAdmincontrolComponent,
        MemberControlOverviewComponent,
        UploadMembersComponent,
        StatusDialogComponent,
        CertificateDialogComponent
    ],
    exports: [
        MemberSummaryComponent,
        SearchMemberComponent,
        SmallMemberSearchComponent,
        MemberNameComponent,
        AddMemberComponent,
        MemberBirthdayComponent,
        MemberPictureComponent,
        MemberAdmincontrolComponent,
        MemberControlOverviewComponent
    ],
    providers: []
})
export class MembersSharedModule {}
