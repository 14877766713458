import { CalendarEvent } from './../../../../interfaces/calendar-event.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-questions-dialog',
    templateUrl: './questions-dialog.component.html',
    styleUrls: ['./questions-dialog.component.scss'],
})
export class QuestionsDialogComponent implements OnInit {
    event: CalendarEvent;
    rangeTickets: number[];

    numberOfTicketsForm = new UntypedFormControl(1);
    paymentMethod = new UntypedFormControl('');

    subscriptionQuestions;
    aleadyPayed: boolean;
    onlyQuestions = false;
    withoutQuestions = false;
    questionsForm: UntypedFormArray = new UntypedFormArray([]);

    constructor(
    @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<QuestionsDialogComponent>
    ) {
        this.event = data.event;
        this.aleadyPayed = data.alreadyPayed;
        if (this.event.canPayWithIdeal) {
            this.paymentMethod.setValue('iDeal');
        }
        if (data.onlyQuestions) {
            this.onlyQuestions = data.onlyQuestions;
        }
        if (data.withoutQuestions) {
            this.withoutQuestions = data.withoutQuestions;
        }

        this.rangeTickets = Array.from(
            { length: data.event.maxNumberTicketsPerSubscription },
            (x, i) => i + 1
        );
    }

    ngOnInit(): void {
        if (this.event.calendarEventQuestions?.length > 0) {
            this.event.calendarEventQuestions.forEach((q) => {
                if (q.requiredAnswer) {
                    this.questionsForm.push(
                        new UntypedFormControl(
                            q.questionType.normalizedName == 'checkbox' ? false : '',
                            Validators.required
                        )
                    );
                } else {
                    this.questionsForm.push(
                        new UntypedFormControl(
                            q.questionType.normalizedName == 'checkbox' ? false : ''
                        )
                    );
                }
            });
        }
    }

    submit() {
        let answers = [];
        if (this.event.calendarEventQuestions?.length > 0) {
            answers = this.event.calendarEventQuestions.map((c, i) => ({
                calendarEventQuestionID: c.calendarEventQuestionID,
                answer: this.questionsForm.controls[i].value,
            }));
        }
        if (this.onlyQuestions) {
            this.dialogRef.close({
                questionAnswers: answers,
            });
        } else if (this.event.paymentNeeded) {
            this.dialogRef.close({
                numberOfTickets: this.numberOfTicketsForm.value,
                paymentMethod: this.paymentMethod.value,
                questionAnswers: answers,
            });
        } else {
            this.dialogRef.close({
                numberOfTickets: this.numberOfTicketsForm.value,
                paymentMethod: '',
                questionAnswers: answers,
            });
        }
    }
}
