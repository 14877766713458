// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.direct-pagination {
  margin-bottom: 0;
}
.direct-pagination mat-form-field {
  max-width: 100px;
  margin: -10px 0 -10px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/pagination/pagination.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,gBAAA;EACA,qBAAA;EACA,mBAAA;AACJ","sourcesContent":[".direct-pagination {\r\n  margin-bottom: 0;\r\n\r\n  mat-form-field {\r\n    max-width: 100px;\r\n    margin: -10px 0 -10px;\r\n    align-items: center;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
