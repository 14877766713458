import { MatSnackBar } from '@angular/material/snack-bar';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { Image } from '../../../../interfaces/image.interface';

@Component({
    selector: 'app-image-picker',
    templateUrl: './image-picker.component.html',
    styleUrls: ['./image-picker.component.scss'],
})
export class ImagePickerComponent implements OnInit, OnChanges {
    @Input() currentImage: Image;
    @Input() aspectRatio: number = 5 / 3;
    @Input() maintainRatio = true;
    @Input() resizeWidth = 1200;
    @Output() image = new EventEmitter<Blob>();
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
    @ViewChild('fileInput', { read: ElementRef }) fileInput: ElementRef;
    fileChangedEvent: Event;
    state: 'empty' | 'existingImage' | 'loading' | 'cropper' = 'empty';

    constructor(private snackBar: MatSnackBar) {}

    ngOnInit() {
        if (this.currentImage) {
            this.state = 'existingImage';
        }
    }
    ngOnChanges() {
        if (this.currentImage) {
            this.state = 'existingImage';
        }
    }

    openFileBrowser() {
        (this.fileInput.nativeElement as HTMLElement).click();
    }

    /**
   * The file input field will trigger this function on change. We need to input this to
   * the image cropper.
   * @param event
   */
    fileChanged(event: Event): void {
        this.state = 'loading';
        this.fileChangedEvent = event;
        console.log(this);
    }

    imageCropped(image: ImageCroppedEvent) {
        this.image.emit(image.blob);
    }

    imageLoaded() {
        this.state = 'cropper';
    }

    loadImageFailed() {
        this.snackBar.open('Kon dit bestand niet openen...', 'OK', {
            duration: 3000,
        });
        this.clear();
    }

    clear() {
        this.state = 'empty';
        this.fileChangedEvent = undefined;
        this.image.emit(undefined);
    }
}
