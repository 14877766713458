import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { UploadMembersComponent } from './upload-members/upload-members.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-member-admincontrol',
    templateUrl: './member-admincontrol.component.html',
    styleUrls: ['./member-admincontrol.component.scss'],
})
export class MemberAdmincontrolComponent implements OnInit {
    today = moment().format();
    isAdmin = false;
    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        private router: Router
    ) {}

    ngOnInit() {
        this.isAdmin = this.authService.hasRole('Admin');
    }

    openUploadMembers() {
        const matchDialog = this.dialog.open(UploadMembersComponent);
        matchDialog.afterClosed().subscribe(() => {
            this.router.navigateByUrl('matches');
        });
    }
}
