import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
    title: string;
    body: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string; body: string }
    ) {
    // Update view with given values
        this.title = data.title;
        this.body = data.body;
    }

    onConfirm(): void {
    // Close the dialog, return true
        this.dialogRef.close(true);
    }

    onDismiss(): void {
    // Close the dialog, return false
        this.dialogRef.close(false);
    }
}
