import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    Optional,
    Self
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    ControlValueAccessor,
    NgControl
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

// datastructure for holding time span
export class TimeSpan {
    constructor(
        public minutes: number | null,
        public seconds: number | null,
        public milliseconds: number | null
    ) {}
}

@Component({
    selector: 'app-time-span-input',
    templateUrl: './time-span-input.component.html',
    styleUrls: ['./time-span-input.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: TimeSpanInputComponent }
    ]
})
export class TimeSpanInputComponent
implements ControlValueAccessor, MatFormFieldControl<TimeSpan>, OnDestroy {
    static nextId = 0;

    parts: UntypedFormGroup;
    stateChanges = new Subject<void>();
    focused = false;
    errorState = false;
    controlType = 'example-tel-input';
    id = `example-tel-input-${TimeSpanInputComponent.nextId++}`;
    describedBy = '';
    onChange: (a:unknown) => void;
    onTouched: () => void;

    get empty() {
        const {
            value: { minutes, seconds, milliseconds }
        } = this.parts;

        return !minutes && !seconds && !milliseconds;
    }

    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    private _placeholder: string;

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.parts.disable() : this.parts.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    @Input()
    get value(): TimeSpan | null {
        const {
            value: { minutes, seconds, milliseconds }
        } = this.parts;

        if (minutes > 0 || seconds > 0 || milliseconds > 0) {
            return new TimeSpan(minutes, seconds, milliseconds);
        }
        return null;
    }
    set value(timeSpan: TimeSpan | null) {
        const { minutes, seconds, milliseconds } =
      timeSpan || new TimeSpan(0, 0, 0);
        if (minutes > 0 || seconds > 0 || milliseconds > 0) {
            this.parts.setValue({ minutes, seconds, milliseconds });
            this.stateChanges.next();
        }
    }

    constructor(
        formBuilder: UntypedFormBuilder,
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Self() public ngControl: NgControl
    ) {
        this.parts = formBuilder.group({
            minutes: null,
            seconds: null,
            milliseconds: null
        });

        _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
            if (this.focused && !origin) {
                this.onTouched();
            }
            this.focused = !!origin;
            this.stateChanges.next();
        });

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            this._elementRef.nativeElement.querySelector('input')!.focus();
        }
    }

    writeValue(tel: TimeSpan | null): void {
        this.value = tel;
    }

    registerOnChange(fn: (a:unknown) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    _handleInput(): void {
        this.onChange(this.parts.value);
    }
}
