import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';

@Component({
    selector: 'app-footer',
    styleUrls: ['footer.component.scss'],
    templateUrl: 'footer.component.html'
})
export class FooterComponent implements OnInit {
    showFooter = true;
    whiteText = false;

    constructor(private layoutService: LayoutService) {}

    ngOnInit() {
        this.layoutService.footerVisibility.subscribe(
            showFooter => (this.showFooter = showFooter)
        );
        this.layoutService.backdropVisibility.subscribe(
            whiteText => (this.whiteText = whiteText)
        );
        this.layoutService.blackBG.subscribe(
            whiteText => (this.whiteText = whiteText)
        );

    }
}
