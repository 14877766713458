import { Component, OnInit, Input } from '@angular/core';
import { GroupTag, Tag } from 'src/app/interfaces/tag.interface';
import {
    Observable,
    merge,
    of,
    Subject,
    combineLatest,
} from 'rxjs';
import { TagService } from 'src/app/services/tag.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Group } from 'src/app/interfaces/group.interface';
import {
    scan,
    map,
    startWith,
    switchMap,
    filter,
    catchError,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

interface GroupTagChange {
    groupTag: GroupTag;
    action: 'add' | 'remove';
}

@Component({
    selector: 'app-add-tag',
    templateUrl: './add-tag.component.html',
    styleUrls: ['./add-tag.component.scss'],
})
export class AddTagComponent implements OnInit {
    constructor(
        private tagService: TagService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar
    ) {}

    /**
   *
   * For now this module is only for group tags but could (should) be expanded for others in the future
   */
    @Input() group: Group;

    autocompleteForm = new UntypedFormControl(null, Validators.required);
    allowedTags$: Observable<Tag[]>;
    groupTags$: Observable<GroupTag[]>;
    groupTagSubject = new Subject<GroupTagChange>();

    ngOnInit(): void {
        this.groupTags$ = merge(
            of(this.group.groupTags),
            this.groupTagSubject.asObservable()
        ).pipe(
            scan((acc: GroupTag[], curr: GroupTag[] | GroupTagChange) => {
                if (Array.isArray(curr)) {
                    return curr as GroupTag[];
                } else {
                    if ((curr as GroupTagChange).action == 'add') {
                        return [...acc, (curr as GroupTagChange).groupTag];
                    } else {
                        return acc.filter(
                            (t) => t.tagNormalizedName != curr.groupTag.tagNormalizedName
                        );
                    }
                }
            }, [])
        );
        this.allowedTags$ = combineLatest(
            this.tagService.getTags(),
            this.groupTags$.pipe(map((t) => t.map((j) => j.tag))),
            this.autocompleteForm.valueChanges.pipe(startWith(''))
        ).pipe(
            map(([allTags, tagsGroup, searchStr]) => {
                if ((searchStr as Tag).value) {
                    return allTags.filter(
                        (t) => !tagsGroup.some((g) => g.normalizedName == t.normalizedName)
                    );
                } else {
                    return allTags.filter(
                        (t) =>
                            !tagsGroup.some((g) => g.normalizedName == t.normalizedName) &&
              t.normalizedName.includes(
                  searchStr.toLowerCase().replace(' ', '')
              )
                    );
                }
            })
        );
    }
    addTag() {
        const value = this.autocompleteForm.value;
        if ((value as Tag).value) {
            // voeg tag toe aan ploeg
            this.tagService
                .postTagForGroup(this.group.groupID, (value as Tag).normalizedName)
                .subscribe((tag) => {
                    this.autocompleteForm.setValue('');
                    this.groupTagSubject.next({
                        action: 'add',
                        groupTag: tag,
                    } as GroupTagChange);
                });
        } else {
            // Voeg een nieuwe tag toe na de vraag of je het echt wilt
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: 'Weet je het zeker?',
                    body:
            'Je staat op het punt een nieuwe Tag toe te voegen. Weet je zeker dat er in het lijstje nog geen vergelijkbare tag stond?',
                },
            });
            dialogRef
                .afterClosed()
                .pipe(
                    filter((confirm) => confirm),
                    switchMap(() => this.tagService.postTag(value)),
                    catchError(() => {
                        this.snackBar.open('Er ging iets mis.', 'OK', { duration: 2000 });
                        return of(undefined);
                    }),
                    filter((t) => t !== undefined),
                    switchMap((tag: Tag) =>
                        this.tagService.postTagForGroup(
                            this.group.groupID,
                            tag.normalizedName
                        )
                    )
                )
                .subscribe((tag) => {
                    this.autocompleteForm.setValue('');
                    this.groupTagSubject.next({
                        action: 'add',
                        groupTag: tag,
                    } as GroupTagChange);
                });
        }
    }
    removeTagFromGroup(gTag: GroupTag) {
        this.tagService
            .deleteTagForGroup(this.group.groupID, gTag.tagNormalizedName)
            .subscribe(() => {
                this.groupTagSubject.next({
                    action: 'remove',
                    groupTag: gTag,
                } as GroupTagChange);
                this.autocompleteForm.setValue('');
            });
    }
    postNewTag() {}
    displayFn(tag: Tag): string {
        return tag ? tag.value : '';
    }
}
