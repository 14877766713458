import { Component, Input } from '@angular/core';
import { Member } from 'src/app/interfaces/member.interface';

@Component({
    selector: 'app-member-picture',
    templateUrl: 'member-picture.component.html',
    styleUrls: ['./member-picture.component.scss']
})
export class MemberPictureComponent {
    @Input() member: Member;
    @Input() size = 50;
    @Input() square = false;
    @Input() highRes = false;

    constructor() {}
}
