// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.member-picture {
  border-radius: 100%;
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.member-picture.square {
  border-radius: 0;
}
.member-picture img {
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/members/member-picture/member-picture.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EAIA,gBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;AAFF;AAJE;EACE,gBAAA;AAMJ;AAAE;EACE,YAAA;EACA,WAAA;AAEJ","sourcesContent":[".member-picture {\r\n  border-radius: 100%;\r\n  &.square {\r\n    border-radius: 0;\r\n  }\r\n  overflow: hidden;\r\n  height: 100%;\r\n  width: 100%;\r\n  margin: 0 auto;\r\n  img {\r\n    height: 100%;\r\n    width: 100%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
