import { LayoutService } from './../../services/layout.service';
import { Component, OnInit, TemplateRef, ViewChild, AfterContentInit, OnDestroy  } from '@angular/core';
import { MatDialog as MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CacheService } from '../../services/cache.service';
import { AccountService } from './../../services/account.service';

@Component({
    selector: 'app-sign-in',
    styleUrls: ['sign-in.component.scss'],
    templateUrl: 'sign-in.component.html'
})
export class SignInComponent implements OnInit, AfterContentInit, OnDestroy  {
    loading = false;

    email = '';
    password = '';
    errorMessage: string = undefined;
    returnUrl: string;

    dialogRef: MatDialogRef<unknown>;
    dialogEmail = '';
    dialogErrorMessage: string = undefined;
    dialogSuccessMessage: string = undefined;
    rememberMe = true;

    @ViewChild('cookieLawDialog', { read: TemplateRef })
        cookieLawDialog: TemplateRef<unknown>;

    constructor(
        private authService: AuthService,
        private accountService: AccountService,
        private router: Router,
        private route: ActivatedRoute,
        private cacheService: CacheService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private layoutService: LayoutService
    ) {}

    ngOnInit() {
        if (!this.cacheService.exists('cookies-accepted')) {
            setTimeout(() => {
                this.openDialog(this.cookieLawDialog);
            }, 0);
        }
        if (this.cacheService.exists('sign-in-remember-me')) {
            this.email = this.cacheService.get<string>('sign-in-remember-me');
        }
    }
    ngAfterContentInit(): void {
        setTimeout(() => this.layoutService.showBackdrop());
    }

    ngOnDestroy(): void {
        this.layoutService.hideBackdrop();
    }

    signIn(): void {
        this.loading = true;
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        if (this.rememberMe) {
            this.cacheService.set('sign-in-remember-me', this.email);
        } else {
            this.cacheService.remove('sign-in-remember-me');
        }

        this.authService.signIn(this.email, this.password).subscribe(
            () => {
                this.errorMessage = undefined;
                this.acceptCookies();
                this.loading = false;
                this.router.navigateByUrl(this.returnUrl);
            },
            error => {
                console.error(error);
                this.loading = false;
                if (error.status == 400){
                    this.snackbar.open('Onbekende gebruikersnaam of wachtwoord', 'OK', {
                        duration: 5000
                    });
                } else {
                    this.snackbar.open('Onbekende fout. Heb je internet en is de server bereikbaar?', 'OK', {
                        duration: 5000
                    });
                }
            }
        );
    }

    openDialog(template: TemplateRef<unknown>): void {
        this.dialogRef = this.dialog.open(template, { width: '500px' });
    }

    acceptCookies(): void {
        this.cacheService.set('cookies-accepted', true);
        this.dialog.closeAll();
    }

    postForgotPassword(): void {
        this.dialogErrorMessage = '';
        if (!this.dialogEmail || this.dialogEmail === '') {
            this.dialogErrorMessage = 'Vul een e-mailadres in';
        }
        this.accountService.forgotPassword(this.dialogEmail).subscribe({
            next: () => {
                this.dialogSuccessMessage = 'Check je mail en volg de link die daar in staat.';
            },
            error: (error) => {
                this.dialogErrorMessage = error;
            }
        });
    }
}
