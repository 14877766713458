import { Component } from '@angular/core';

/**
 * This component shows an event summary. Is used in other components.
 */
@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
    constructor() {}
}
