import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SignedInGuard  {
    constructor(public auth: AuthService, public router: Router) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuth = await this.auth.userLoggedIn.pipe(take(1)).toPromise();

        if (!isAuth) {
            this.router.navigate(['sign-in'], {
                queryParams: { returnUrl: state.url != '/sign-out' ? state.url : '/' },
            });
            return false;
        }
        return true;
    }
}
