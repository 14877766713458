import { UserinfoService } from 'src/app/services/userinfo.service';
import { filter, switchMap, startWith } from 'rxjs/operators';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';
import { Notification } from '../../interfaces/notification.interface';
import { Observable, BehaviorSubject, interval, combineLatest, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-notification-center',
    templateUrl: './notification-center.component.html',
    styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit, OnDestroy {
    unopenedCount: number;
    notifications: Notification[];
    userOnPage = true;
    memberID: number;
    focusChange$ = new BehaviorSubject(true);

    sub: Subscription;

    constructor(
        private notificationsService: NotificationsService,
        private userinfoService: UserinfoService,
        private router: Router,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.userinfoService.getUserID().subscribe(memberID => {
            this.memberID = memberID;
        });
        this.sub = this.pollNotifications().subscribe(notifications => {
            this.notifications = notifications;
        });
        this.notificationsService.unopened.subscribe(unopened => {
            this.unopenedCount = unopened;
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    /**
   * Polls the server for notifications every 10 seconds
   * TODO: change this to some sort of server-initiative connection sometime in the future
   */
    pollNotifications(): Observable<Notification[]> {
        const interval$ = interval(30000);
        // Combine an interval with the window:focus change events
        // So this observable will fire when the user focuses on the page
        // and every 30 seconds (if the page is focused at that time)
        const poll$ = combineLatest([interval$, this.focusChange$]).pipe(
            // Only continue when the page is focussed
            filter(([, focus]) => focus),
            // Start with an emit so we always get the notifications in the beginning,
            // no matter what
            startWith([0, true])
        );

        return poll$.pipe(
            switchMap(() => this.notificationsService.getNotifications())
        );
    }

    markNotificationsAsOpened() {
    // Mark all notifications as opened at the backend
        this.notificationsService
            .markNotificationsAsOpened()
            .subscribe(notifications => {
                // Locally reflect these changes but with a delay so the
                // user can notice what notifications are new
                setTimeout(() => {
                    this.notifications = notifications;
                }, 2000);
            });
    }

    @HostListener('window:focus', ['$event']) onFocus(): void {
        this.userOnPage = true;
        this.focusChange$.next(true);
    }

    @HostListener('window:blur', ['$event']) onBlur(): void {
        this.userOnPage = false;
        this.focusChange$.next(false);
    }
}
