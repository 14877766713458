import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, filter, switchMap, map } from 'rxjs/operators';
import { Member } from '../../../interfaces/member.interface';
import { MembersService } from '../../../services/members.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-small-member-search',
    templateUrl: './small-member-search.component.html',
    styleUrls: ['./small-member-search.component.scss']
})
export class SmallMemberSearchComponent {
    filteredMembers: Observable<Member[]>;

    searchCtrl = new UntypedFormControl();

    @Output()
        memberSelectedEmitter = new EventEmitter();

    @Input()
        filterMembers = [];

    @Input()
        retainSelected = false;

    constructor(private memberService: MembersService) {
        this.filteredMembers = this.searchCtrl.valueChanges.pipe(
            filter(value => value.length > 2),
            debounceTime(300),
            switchMap(value =>
                this.memberService
                    .getMembersBySearch(value)
                    .pipe(
                        map(h =>
                            h.filter(j => !this.filterMembers.some(g => g === j.memberID))
                        )
                    )
            )
        );
    }

    selectedMember() {
        this.memberSelectedEmitter.emit(this.searchCtrl.value);
        if (!this.retainSelected) {
            this.searchCtrl.setValue('');
        }
    }
    getMemberName(member: Member) {
        if (member) {
            return (
                member.firstName +
        ' ' +
        (member.infix ? member.infix + ' ' : '') +
        member.lastName
            );
        }
    }
}
