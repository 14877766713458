// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-picker img {
  max-width: 100%;
}

.imageButton {
  margin: 16px;
}
.imageButton mat-icon {
  margin-right: 5px;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/image-picker/image-picker.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;;AAGA;EACE,YAAA;AAAF;AACE;EACE,iBAAA;EACA,kBAAA;AACJ","sourcesContent":[".image-picker {\r\n  img {\r\n    max-width: 100%;\r\n  }\r\n}\r\n.imageButton{\r\n  margin: 16px;\r\n  mat-icon{\r\n    margin-right: 5px;\r\n    margin-bottom: 5px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
