import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class UnauthorizedRequestInterceptor implements HttpInterceptor {
    constructor(
        public auth: AuthService,
        private router: Router,
        private snackBar: MatSnackBar
    ) {}

    /**
   * Intercepts HTTP requests and if the response has status 401, redirect the
   * user to the signout page.
   * @param request
   * @param next
   */
    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((err, _caught) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    return this.auth.refreshTokenObs().pipe(
                        switchMap((res) => {
                            const req = request.clone({
                                headers: new HttpHeaders({
                                    Authorization: `Bearer ${res.token}`,
                                }),
                            });
                            return next.handle(req);
                        })
                    );
                } else {
                    return throwError(err);
                }
            })
        );
    }
}
