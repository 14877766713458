import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { ArticlesService } from 'src/app/services/articles.service';
import { Observable } from 'rxjs';
import { Article } from 'src/app/interfaces/article.interface';

@Component({
    selector: 'app-search-article',
    templateUrl: './search-article.component.html',
    styleUrls: ['./search-article.component.scss'],
})
export class SearchArticleComponent implements OnInit {
    constructor(private ArticleService: ArticlesService) {}
    @Output()
        articleSelectedEmitter = new EventEmitter();

    searchCtrl = new UntypedFormControl('');
    results$: Observable<Article[]>;
    ngOnInit(): void {
        this.results$ = this.searchCtrl.valueChanges.pipe(
            debounceTime(300),
            filter((f) => f.length > 0),
            switchMap((query) => this.ArticleService.getArticles({ query: query }))
        );
    }
    selectedArticle() {
        this.articleSelectedEmitter.emit(this.searchCtrl.value);
        this.searchCtrl.setValue('');
    }
    getArticleName(article: Article) {
        if (article) {
            return article.title;
        } else {
            return '';
        }
    }
}
