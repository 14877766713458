import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, tap, startWith } from 'rxjs/operators';
import {
    Member,
    GetExternalMember,
    MemberChanges,
    PendingChange,
} from 'src/app/interfaces/member.interface';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { environment } from '../../environments/environment';
import {
    CertificateGrant,
} from './../interfaces/certificate.interface';
import { BadgeGrant } from '../interfaces/badge.interface';
import { CacheService } from './cache.service';
import { FormattingService } from './formatting.service';
import { FindMembers } from '../interfaces/find-members.interface';
import { Type } from '../interfaces/type.interface';

/**
 * This service contains all functions to call api/members
 */
@Injectable({ providedIn: 'root' })
export class MembersService {

    private pendingCountReloadSubject = new Subject<void>();

    bicLookup = {
        ABNA: 'ABNANL2A',
        AEGO: 'AEGONL2U',
        ASRN: 'ARSNNL21',
        ANAA: 'ANAANL21',
        ANDL: 'ANDLNL2A',
        ARBN: 'ARBNNL22',
        ARSN: 'ARSNNL21',
        ARTE: 'ARTENL2A',
        ASNB: 'ASNBNL21',
        ATBA: 'ATBANL2A',
        BBRU: 'BBRUNL2X',
        BCDM: 'BCDMNL22',
        BCIT: 'BCITNL2A',
        BICK: 'BICKNL2A',
        BKCH: 'BKCHNL2R',
        BKMG: 'BKMGNL2A',
        BMEU: 'BMEUNL21',
        BNGH: 'BNGHNL2G',
        BNPA: 'BNPANL2A',
        BOFA: 'BOFANLNX',
        BOFS: 'BOFSNL21002',
        BUNQ: 'BUNQNL2A',
        CEBU: 'CEBUNL2U',
        CITI: 'CITINL2X',
        CHAS: 'CHASNL2X',
        CITC: 'CITCNL2A',
        COBA: 'COBANL2X',
        DEUT: 'DEUTNL2N',
        DHBN: 'DHBNNL2R',
        DLBK: 'DLBKNL2A',
        FVLB: 'FVLBNL22',
        DNIB: 'DNIBNL2G',
        FBHL: 'FBHLNL2A',
        FLOR: 'FLORNL2A',
        FRBK: 'FRBKNL2L',
        FRGH: 'FRGHNL21',
        GILL: 'GILLNL2A',
        HAND: 'HANDNL2A',
        HHBA: 'HHBANL22',
        HSBC: 'HSBCNL2A',
        ICBK: 'ICBKNL2A',
        INGB: 'INGBNL2A',
        INSI: 'INSINL2A',
        KABA: 'KABANL2A',
        KNAB: 'KNABNL2H',
        KOEX: 'KOEXNL2A',
        KRED: 'KREDNL2X',
        LOYD: 'LOYDNL2A',
        LPLN: 'LPLNNL2F',
        MHCB: 'MHCBNL2A',
        NNBA: 'NNBANL2G',
        OVBN: 'OVBNNL22',
        RABO: 'RABONL2U',
        RBOS: 'RBOSNL2A',
        RBRB: 'RBRBNL21',
        SNSB: 'SNSBNL2A',
        STAL: 'STALNL2G',
        TRIO: 'TRIONL2U',
        UGBI: 'UGBINL2A',
        VOWA: 'VOWANL21',
        ZWLB: 'ZWLBNL21',
    };

    constructor(
        private http: HttpClient,
        private cache: CacheService,
        private formattingService: FormattingService,
        private userInfoService: UserinfoService
    ) {}

    /**
   * Get all members from the API
   */

    getMembers(): Observable<Member[]> {
    // Compose the endpoint
        const endpoint = environment.api.baseUrl + 'Members';
        // Create the HTTP request observable
        return this.http
            .get<Member[]>(endpoint)
            .pipe(this.formattingService.mapMultipleUtcToLocal(['birthdate']));
    }

    // Get members from api based on search query
    getMembersBySearch(query: string): Observable<Member[]> {
        if (query.length < 3) {
            return of([]);
        }
        const endpoint =
      environment.api.baseUrl + 'Members' + '?searchQuery=' + query;
        return this.http
            .get<Member[]>(endpoint)
            .pipe(this.formattingService.mapMultipleUtcToLocal(['birthdate']));
    }

    getBirthdayMembers(offset: number): Observable<Member[]> {
        const endpoint = `${environment.api.baseUrl}Members/birthdays?offset=${offset}`;

        // Create the HTTP request observable
        return this.http
            .get<Member[]>(endpoint)
            .pipe(this.formattingService.mapMultipleUtcToLocal(['birthdate']));
    }

    /**
   * Get a single member by its ID
   * @param id
   */
    getMember(id: number): Observable<Member> {
        const endpoint = environment.api.baseUrl + 'Members/' + id;
        const member$ = this.http.get<Member>(endpoint);
        return this.cache
            .serveFromCacheOr('member-' + id, member$, {
                maxAge: 30 * 60,
                tags: ['member'],
            })
            .pipe(this.formattingService.mapUtcToLocal(['birthdate']));
    }
    getExternalMember(email: string) {
        const endpoint = environment.api.baseUrl + 'Members/external';
        let params = new HttpParams();
        params = params.append('email', email);
        return this.http.get<GetExternalMember>(endpoint, { params: params });
    }
    getMemberName(member: Member) {
        return member.infix
            ? `${member.firstName} ${member.infix} ${member.lastName}`
            : `${member.firstName} ${member.lastName}`;
    }

    /**
   * Get a single member certificates by member ID
   * @param id
   */
    getMemberCertificates(id: number): Observable<CertificateGrant[]> {
        const endpoint =
      environment.api.baseUrl + 'Members/' + id + '/Certificates';
        return this.cache.serveFromCacheOr(
            'member-' + id + '-certificates',
            this.http.get<CertificateGrant[]>(endpoint),
            { maxAge: 60 * 5 }
        );
    }

    getMemberBadges(id: number): Observable<BadgeGrant[]>{
        const endpoint = environment.api.baseUrl + 'members/' + id + '/badges';
        return this.http.get<BadgeGrant[]>(endpoint);

    }
    getMyChanges() {
        return this.http.get<MemberChanges[]>(
            environment.api.baseUrl + 'members/changes'
        );
    }
    postMyChange(changes: {
        [key: string]: string | number;
    }): Observable<string> {
        const endpoint = environment.api.baseUrl + 'Members/update';

        return this.http.put<string>(endpoint, changes);
    }

    /**
   * Put a single member by its ID
   * @param id
   * @param member
   */
    putMember(id: number, member: Member) {
        if (member.email == '') {
            member.email = null;
        }
        const endpoint = environment.api.baseUrl + 'Members/' + id;
        this.cache.remove('member-' + id);
        return this.http.put(endpoint, member);
    }

    putMyProfileChange(member: Member) {
        const endpoint = environment.api.baseUrl + 'Members/me';
        return this.http.put(endpoint, member);
    }
    putMyEmailChange(ems: { email: string; preferedEmail: string }) {
        if (ems.preferedEmail == '') {
            ems.preferedEmail = null;
        }
        const endpoint = environment.api.baseUrl + 'Members/me/email';
        return this.http.put(endpoint, ems);
    }
    cancelMyEmailChange() {
        const endpoint = environment.api.baseUrl + 'Members/me/email/cancel';
        return this.http.get(endpoint);
    }

    /**
   * Post a single member to the API
   */
    postMember(member: Member) {
        const endpoint = environment.api.baseUrl + 'Members';
        member.birthdate = this.formattingService.convertLocalToUtc(
            member.birthdate
        );
        return this.http.post(endpoint, member);
    }

    postMemberCertificate(certificateGrant): Observable<CertificateGrant[]> {
        const memberID = certificateGrant.memberID;
        const endpoint =
      environment.api.baseUrl + `Members/${memberID}/Certificates`;
        return this.http.post<CertificateGrant[]>(endpoint, certificateGrant).pipe(
            tap((certificates) => {
                this.cache.set(`member-${memberID}-certificates`, certificates, {
                    maxAge: 60 * 5,
                });
            })
        );
    }

    /**
   * Award badge to a member
   */
    postMemberBadge(badgeGrant): Observable<BadgeGrant[]> {
        const memberID = badgeGrant.MemberID;
        const endpoint = environment.api.baseUrl + `badges/${memberID}/badges`;
        return this.http.post<BadgeGrant[]>(endpoint, badgeGrant);
    }

    /**
   * Post a single member to the API
   */
    postNewMembers(file: File, statusTypeID: number, memberSince) {
        memberSince = this.formattingService.convertLocalToUtc(memberSince);
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('memberStatusTypeID', statusTypeID.toString());
        formData.append('memberSince', memberSince);

        const endpoint = environment.api.baseUrl + 'Members/csvupload';

        return this.http.post(endpoint, formData);
    }

    /**
   * Removes certificate with given ID for the given member
   */
    deleteMemberCertificate(
        memberID: number,
        certificateID: number
    ): Observable<CertificateGrant[]> {
        const endpoint =
      environment.api.baseUrl +
      `Members/${memberID}/Certificates/${certificateID}`;
        return this.http.delete<CertificateGrant[]>(endpoint).pipe(
            // Set new certificates in cache
            tap((certificates) => {
                this.cache.set(`member-${memberID}-certificates`, certificates, {
                    maxAge: 60 * 5,
                });
            })
        );
    }

    deleteMemberBadge(
        memberID: number,
        badgeID: number
    ): Observable<BadgeGrant[]> {
        const endpoint = environment.api.baseUrl + `Members/${memberID}/badges/${badgeID}`;
        return this.http.delete<BadgeGrant[]>(endpoint);
    }


    changeMultipleStatus(selection: Member[], status: Type) {
        const endpoint = environment.api.baseUrl + 'Members/ChangeMultipleStatus';
        const formd = {
            members: selection,
            status: status,
        };
        return this.http.post(endpoint, formd);
    }

    /**
   * Post a request with body to fetch All members with these criteria
   * @param request
   */
    findMembers(request: FindMembers) {
        const endpoint = environment.api.baseUrl + 'Members/FindMembers';

        return this.http.post<Member[]>(endpoint, request);
    }

    timeUntilBirthday(member: Member): number {
        const now = moment().startOf('day');
        const birthDate = moment(member.birthdate).year(now.year()).startOf('day');
        return birthDate.diff(now);
    }

    getPendingMemberChanges() {
        const endpoint = environment.api.baseUrl + 'Members/changes/pending';

        return this.http.get<PendingChange[]>(endpoint);
    }
    reloadPendingCount() {
        this.pendingCountReloadSubject.next();
    }

    getPendingMemberChangesCount() {
        const endpoint = environment.api.baseUrl + 'Members/changes/pending/count';

        return this.pendingCountReloadSubject.pipe(
            startWith(''),
            switchMap(() => this.http.get<number>(endpoint))
        );
    }

    putMemberChangeApproval(approve: {
        memberChangeID: number;
        approved: boolean;
    }) {
        const endpoint = environment.api.baseUrl + 'Members/changes';

        return this.http.put<PendingChange[]>(endpoint, approve);
    }

    getBICCodeDutchBank(IBAN: string) {
        const regex = new RegExp(
            /^([a-zA-Z]{2}[0-9]{2})([a-zA-Z0-9]{4})[0-9]{7}([a-zA-Z0-9]?){0,16}$/i
        );

        if (regex.test(IBAN)) {
            const [_country, _code, bank] = IBAN.match(regex);
            return this.bicLookup[bank] || false;
        } else {
            return null;
        }
    }
}
