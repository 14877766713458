import { Component, OnInit } from '@angular/core';
import { TypesService } from 'src/app/services/types.service';
import { Type } from 'src/app/interfaces/type.interface';
import { MembersService } from 'src/app/services/members.service';

@Component({
    selector: 'app-upload-members',
    templateUrl: './upload-members.component.html',
    styleUrls: ['./upload-members.component.scss']
})
export class UploadMembersComponent implements OnInit {
    fileName: string;
    fileSize: number;
    file: File;
    memberStatusTypes: Type[];
    memberStatusID: number;
    memberSince: Date;
    correctFile = true;
    uploading = false;

    error: string;
    constructor(
        private typesService: TypesService,
        private membersService: MembersService
    ) {}

    ngOnInit() {
        this.typesService.getTypes('MemberStatus').subscribe((res: Type[]) => {
            this.memberStatusTypes = res;
        });
    }

    public changeListener(files: FileList) {
        console.log(files);
        if (files && files.length > 0) {
            this.file = files.item(0);
            console.log(this.file.name);
            this.fileName = this.file.name;
            this.fileSize = this.file.size;
            if (this.file.type !== 'text/csv') {
                this.correctFile = false;
            } else {
                this.correctFile = true;
            }
        }
    }
    public toMb(size) {
        return Math.round(size * 0.1) / 100;
    }
    public saveFile() {
        if (this.correctFile && this.memberStatusID && this.memberSince) {
            this.uploading = true;
            this.membersService
                .postNewMembers(this.file, this.memberStatusID, this.memberSince)
                .subscribe(
                    response => {
                        console.log(response);
                        this.uploading = false;
                    },
                    error => {
                        console.error(error);
                        this.error = error.error;
                        this.uploading = false;
                    }
                );
        }
    }
}
