import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { Member } from '../../../interfaces/member.interface';
import { MembersService } from '../../../services/members.service';

/**
 * This component shows a field to search for members and shows members below that field.
 */
@Component({
    selector: 'app-search-member',
    templateUrl: './search-member.component.html',
    styleUrls: ['./search-member.component.css']
})
export class SearchMemberComponent implements OnInit {
    query = '';
    searchResults = undefined;

    constructor(private memberService: MembersService) {}

    // Input to set extraData shown in the member's summary (for example birthdate or telephonenumber)
    @Input()
        extraData: string;

    @Input()
        maxWidth = 4;
    // emits the member after the user has clicked on it to use in the 'parent' component.
    @Output()
        userClick: EventEmitter<Member> = new EventEmitter();

    keyUp = new BehaviorSubject(1);

    ngOnInit() {
        this.keyUp
            .pipe(
                debounceTime(250),
                filter(() => this.query && this.query !== ''),
                switchMap(() => this.memberService.getMembersBySearch(this.query))
            )
            .subscribe((members: Member[]) => {
                this.searchResults = members;
            });
    }

    emitKeyUp() {
        this.keyUp.next(1);
    }

    // emit member after a member summary is clicked.
    userClicked(member: Member) {
    // this.query = member.firstName + ' ' + member.lastName;
    // this.searchResults = [member];
        this.userClick.emit(member);
    }
}
