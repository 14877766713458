import { Component, OnInit, Inject } from '@angular/core';
import { TypesService } from 'src/app/services/types.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Member } from 'src/app/interfaces/member.interface';
import { CertificatesService } from 'src/app/services/certificates.service';
import {
    Certificate,
    CertificateGrant
} from 'src/app/interfaces/certificate.interface';
import { UntypedFormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { UserinfoService } from 'src/app/services/userinfo.service';

@Component({
    selector: 'app-certificate-dialog',
    templateUrl: './certificate-dialog.component.html',
    styleUrls: ['./certificate-dialog.component.scss']
})
export class CertificateDialogComponent implements OnInit {
    selection: Array<Member>;
    possibleCerts: Certificate[];
    committing = false;
    certificateForm = new UntypedFormControl(undefined, Validators.required);
    userID: number;
    constructor(
        public typesService: TypesService,
        public dialogRef: MatDialogRef<CertificateDialogComponent>,
        public certificatesService: CertificatesService,
        public userInfoService: UserinfoService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA)
        public data: { selection: Array<Member> }
    ) {
    // Update view with given values
        this.selection = data.selection;
        this.certificatesService.getCertificates().subscribe(gh => {
            this.possibleCerts = gh;
        });
    }
    ngOnInit() {
        this.userInfoService.getUserID().subscribe(id => {
            this.userID = id;
        });
    }

    onDismiss(): void {
    // Close the dialog, return false
        this.dialogRef.close(false);
    }
    onCommit() {
        this.committing = true;
        const certsArray: Array<CertificateGrant> = [];
        this.selection.forEach((mem: Member) => {
            certsArray.push({
                certificateID: this.certificateForm.value,
                date: moment().format(),
                examiner: this.userID,
                memberID: mem.memberID
            });
        });

        this.certificatesService
            .postMultipleCertificates(certsArray)
            .subscribe((success: string) => {
                this.snackBar.open(success, 'OK', { duration: 3000 });
                this.dialogRef.close();
                this.committing = false;
            });
    }
}
