import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-fab-menu',
    templateUrl: './fab-menu.component.html',
    styleUrls: ['./fab-menu.component.scss']
})
export class FabMenuComponent {
    fabVisible = false;

    @Input() color: string;

    constructor() { }

}
