// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tel-input-container {
  display: flex;
}

.tel-input-element {
  border: none;
  background: none;
  padding: 0;
  max-width: 33%;
  outline: none;
  font: inherit;
  text-align: center;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/time-span-input/time-span-input.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;EACA,UAAA;EACA,cAAA;EACA,aAAA;EACA,aAAA;EACA,kBAAA;AACF;;AACA;;EAEE,wBAAA;EACA,SAAA;AAEF;;AACA;EACE,0BAAA;AAEF","sourcesContent":["tel-input-container {\r\n  display: flex;\r\n}\r\n\r\n.tel-input-element {\r\n  border: none;\r\n  background: none;\r\n  padding: 0;\r\n  max-width: 33%;\r\n  outline: none;\r\n  font: inherit;\r\n  text-align: center;\r\n}\r\ninput[type=\"number\"]::-webkit-outer-spin-button,\r\ninput[type=\"number\"]::-webkit-inner-spin-button {\r\n  -webkit-appearance: none;\r\n  margin: 0;\r\n}\r\n\r\ninput[type=\"number\"] {\r\n  -moz-appearance: textfield;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
