import { DateFormatPipe } from 'ngx-moment';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { OperatorFunction } from 'rxjs';

/**
 * This service provides some data-formatting functions that are used thoughout the application.
 */
@Injectable({
    providedIn: 'root'
})
export class FormattingService {
    dateFormat = 'YYYY-MM-DDTHH:mm:ss';

    constructor() {}

    /**
   * Returns an OperatorFunction (to be used to pipe on an Observable) which
   * converts date fields from UTC to the local timezone for the object in an emit.
   * @param keys the keys of the fields containing dates in the objects
   */
    mapUtcToLocal<T>(keys: string[]): OperatorFunction<T, T> {
    // Map the emit
        return map((item: T) => {
            // Loop over all keys for the item
            keys.forEach(key => {
                // Convert UTC to local
                item[key] = this.convertUtcToLocal(item[key]);
            });
            return item;
        });
    }

    /**
   * Returns an OperatorFunction (to be used to pipe on an Observable) which
   * converts date fields from UTC to the local timezone.
   * This 'multiple' version of this function is to be used on Observables that emit
   * arrays with items, e.g. an array of Articles
   * @param keys the keys of the fields containing dates in the objects
   */
    mapMultipleUtcToLocal<T>(keys: string[]): OperatorFunction<T[], T[]> {
    // Map the emit
        return map((items: T[]) =>
        // Map the array
            items.map(item => {
                // Loop over all keys for this item in the array
                keys.forEach(key => {
                    // Convert UTC to local
                    item[key] = this.convertUtcToLocal(item[key]);
                });
                return item;
            })
        );
    }

    /**
   * Converts a local datetime to UTC datetime
   * @param date local datetime
   */
    convertLocalToUtc(date: Date | string): string {
        if (date === undefined) {
            return undefined;
        }
        return moment(date)
            .utc()
            .format(this.dateFormat);
    }

    /**
   * Converts an UTC datetime to local datetime
   * @param date UTC datetime
   */
    convertUtcToLocal(date: Date | string): string {
        return moment(moment.utc(date).toDate()).format(this.dateFormat);
    }

    /**
   * Takes an object and array of keys of that object and converts the value
   * for each of those keys from local datetime to UTC datetime.
   * @param obj local datetime
   * @param keys
   */
    convertMultipleLocalToUtc<T>(obj: T, keys: string[]): T {
        keys.forEach(key => {
            if (obj[key]) {
                obj[key] = this.convertLocalToUtc(obj[key]);
            }
        });
        return obj;
    }

    getTimeStringFromDateObject(obj: Date): string {
        const h = obj.getHours();
        const m = obj.getMinutes();
        return (h < 10 ? '0' + h : h + '') + ':' + (m < 10 ? '0' + m : m + '');
    }

    /**
   * Takes a time string such as '10:15' and returns a Date object where the hours
   * and minutes correspond to the given string.
   */
    getDateObjectFromTimeString(time: string): Date {
        const d = new Date();
        const h = parseInt(time.split(':')[0], 10);
        const m = parseInt(time.split(':')[1], 10);
        d.setHours(h);
        d.setMinutes(m);
        return d;
    }

    getApiDateFromDateObject(obj: Date): string {
        return new DateFormatPipe().transform(obj, 'YYYY-MM-DDTHH:mm:ss');
    }
}
