// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableoverflow {
  width: 100%;
  overflow: auto;
  margin-bottom: 70px;
}

table {
  min-width: 100%;
}
table .mat-mdc-row:hover {
  background-color: rgb(247, 247, 250);
}
table .selected-row {
  background-color: rgb(214, 214, 214);
}

button.mat-mdc-fab {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 1000;
}

.filterrow .mat-mdc-form-field {
  padding: 5px;
}

.hidden {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/members/member-admincontrol/member-control-overview/member-control-overview.component.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,cAAA;EACA,mBAAA;AADF;;AAGA;EACE,eAAA;AAAF;AAGI;EACE,oCAAA;AADN;AAIE;EACE,oCAAA;AAFJ;;AAKA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AAFF;;AAKE;EACE,YAAA;AAFJ;;AAKA;EACE,aAAA;AAFF","sourcesContent":["@import \"./../../../../../mixins.scss\";\r\n@import \"./../../../../../palette.scss\";\r\n.tableoverflow {\r\n  width: 100%;\r\n  overflow: auto;\r\n  margin-bottom: 70px;\r\n}\r\ntable {\r\n  min-width: 100%;\r\n\r\n  .mat-mdc-row {\r\n    &:hover {\r\n      background-color: orca-color(\"light-tone\");\r\n    }\r\n  }\r\n  .selected-row {\r\n    background-color: orca-color(\"accent-tone\");\r\n  }\r\n}\r\nbutton.mat-mdc-fab {\r\n  position: fixed;\r\n  right: 30px;\r\n  bottom: 30px;\r\n  z-index: 1000;\r\n}\r\n.filterrow {\r\n  .mat-mdc-form-field {\r\n    padding: 5px;\r\n  }\r\n}\r\n.hidden {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
