// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-form .mat-mdc-form-field {
  padding: 5px;
}

.filterbox {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/members/member-admincontrol/member-admincontrol.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;;AAGA;EACE,aAAA;AAAF","sourcesContent":[".filter-form {\r\n  .mat-mdc-form-field {\r\n    padding: 5px;\r\n  }\r\n}\r\n.filterbox {\r\n  padding: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
