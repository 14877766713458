// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-list {
  margin: 10px;
}

.document-upload {
  margin: 10px;
}

.pdf_table {
  margin: 10px;
  margin-right: 20px;
}
.pdf_table table {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/documents/my-documents/my-documents.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,YAAA;EACA,kBAAA;AAGF;AAFE;EACE,WAAA;AAIJ","sourcesContent":[".document-list {\r\n  margin: 10px;\r\n}\r\n.document-upload {\r\n  margin: 10px;\r\n}\r\n.pdf_table {\r\n  margin: 10px;\r\n  margin-right: 20px;\r\n  table {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
