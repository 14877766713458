// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
::ng-deep .mat-slide-toggle-content {
  white-space: normal !important;
}

.mat-mdc-slide-toggle span {
  white-space: normal !important;
}

.mat-mdc-slide-toggle {
  padding: 20px;
  margin-top: 50px;
}

.mat-mdc-dialog-content {
  min-height: 400px;
}

.list-item-form {
  padding-top: 20px !important;
  padding-bottom: 10px !important;
}

.mat-mdc-list-item {
  margin-top: 15px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/questions-dialog/questions-dialog.component.scss"],"names":[],"mappings":"AACA,iIAAA;AACA;EACE,8BAAA;AAAF;;AAGE;EACE,8BAAA;AAAJ;;AAGA;EACE,aAAA;EACA,gBAAA;AAAF;;AAEA;EACE,iBAAA;AACF;;AACA;EAEE,4BAAA;EACA,+BAAA;AACF;;AACA;EACE,2BAAA;AAEF","sourcesContent":["\r\n/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/\r\n::ng-deep .mat-slide-toggle-content {\r\n  white-space: normal !important;\r\n}\r\n.mat-mdc-slide-toggle {\r\n  span {\r\n    white-space: normal !important;\r\n  }\r\n}\r\n.mat-mdc-slide-toggle {\r\n  padding: 20px;\r\n  margin-top: 50px;\r\n}\r\n.mat-mdc-dialog-content {\r\n  min-height: 400px;\r\n}\r\n.list-item-form {\r\n  //min-height: 60px;\r\n  padding-top: 20px !important;\r\n  padding-bottom: 10px !important;\r\n}\r\n.mat-mdc-list-item {\r\n  margin-top: 15px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
