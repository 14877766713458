import { SelectionModel } from '@angular/cdk/collections';
import { filter, map, switchMap, take } from "rxjs/operators";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    Input,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MemberDocument } from 'src/app/interfaces/document.interface';
import { DocumentsService } from 'src/app/services/documents.service';
import {Clipboard} from '@angular/cdk/clipboard';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from "../../dialogs/confirm-dialog/confirm-dialog.component";


@Component({
    selector: 'app-my-documents',
    templateUrl: './my-documents.component.html',
    styleUrls: ['./my-documents.component.scss'],
})
export class MyDocumentsComponent implements OnInit, AfterViewInit {
    @ViewChild('fileInput', { read: ElementRef }) fileInput: ElementRef;

    file: File;
    fileChangedEvent = '';
    selection = new SelectionModel<MemberDocument>(true, []);

    data: MemberDocument[] = [];
    dataSource = new MatTableDataSource<MemberDocument>();

    nameForm = new UntypedFormControl('', Validators.required);

    resultsLength = 0;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @Output() selectionChangeEmitter = new EventEmitter<MemberDocument[]>();

    // Dit is even alleen de bedoeling voor de informatiepaginas
    @Input() showLink: boolean = false;
    columns : string[];

    constructor(
        private snackBar : MatSnackBar,
        private dialog: MatDialog,
        private documentService: DocumentsService,
        public changeDetectorRefs: ChangeDetectorRef, // Ik heb dit veranderd naar public omdat ik het in informationcomponent wil gebruiken, nodig om de table opnieuw te renderen
        private clipboard: Clipboard // Dit is zodat bestuur met één klik kan kopiëren
    ) {}

    ngOnInit(): void {
        // load columns
        if (this.showLink){
            this.columns = ['select', 'fileName', 'dateAdded', 'download', 'downloadLink', 'delete']
        }
        else{
            this.columns = ['select', 'fileName', 'dateAdded', 'download', 'delete']
        }
        

        // Load my existing files
        this.documentService.getMyDocuments().subscribe((docs) => {
            this.resultsLength = docs.length;
            this.data = docs;
            this.dataSource.data = docs;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.changeDetectorRefs.detectChanges();
        });
    }
    resetForm() {
        this.nameForm.reset();
        this.file = undefined;
    }
    ngAfterViewInit() {
        this.selection.changed.subscribe(() => {
            this.selectionChangeEmitter.emit(this.selection.selected);
        });
    }

    downloadFile(fileUrl) {
        window.open(fileUrl);
    }

    uploadFile() {
        if (this.file && this.nameForm.valid) {
            this.documentService
                .postDocument({ file: this.file, fileName: this.nameForm.value })
                .subscribe((newDoc) => {
                    // this.data = [newDoc, ...this.data];
                    this.nameForm.reset();
                    this.file = undefined;
                    this.dataSource.data = [newDoc, ...this.dataSource.data];
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                    this.changeDetectorRefs.detectChanges();
                },
                (error) => {
                    console.log(error);
                });
        }
    }

    openFileBrowser() {
        (this.fileInput.nativeElement as HTMLElement).click();
    }

    fileChanged(event: Event): void {
        const files = (event.target as HTMLInputElement).files;
        if (files.length > 0) {
            this.file = files[0];
        }
    }

    copy(doc : MemberDocument): void {
        if (!doc.documentLink.endsWith("pdf")){
            this.clipboard.copy("![" + doc.fileName +  "]" + "(" + doc.documentLink + ")");
        }
        else {
            this.clipboard.copy("[" + doc.fileName +  "]" + "(" + doc.documentLink + ")");
        }
    }

    sub : any;

    delete(row : MemberDocument) : void {

        if (this.showLink){
            var dialogbody = "Weet je zeker dat je dit document wil verwijderen? Je kan het niet ongedaan maken. Als je de link ergens in een informatiepagina hebt gebruikt, moet je deze weer handmatig verwijderen.";
        }
        else {
            var dialogbody = "Weet je zeker dat je dit document wil verwijderen? Je kan het niet ongedaan maken.";
        }

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: "Weet je het zeker?",
              body: dialogbody
                
            },
          });
          this.sub = dialogRef
            .afterClosed()
            .pipe(
              filter((confirm) => !!confirm),
              switchMap(() => this.documentService.deleteDocument(row)))
              .subscribe(() => {
                this.dataSource.data = this.dataSource.data.filter(r => r.memberDocumentID != row.memberDocumentID);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.changeDetectorRefs.detectChanges(); // Volgens mij hebben we de hele changedect.refs niet nodig maargoed
            }, (error) => {
                this.snackBar.open(error.error, "OK", { duration: 3000 });
            }
            );
        
    }

    ngOnDestroy(){
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
