// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  text-align: center;
}
:host mat-progress-spinner {
  margin: 20px auto 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/spinner/spinner.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD;AAAC;EACC,sBAAA;AAEF","sourcesContent":[":host {\r\n\ttext-align: center;\r\n\tmat-progress-spinner {\r\n\t\tmargin: 20px auto 30px;\r\n\t}\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
