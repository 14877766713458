import { Member } from 'src/app/interfaces/member.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';

/**
 * Service which contains all functions to call api/acounts
 */
@Injectable({
    providedIn: 'root',
})
export class AccountService {
    constructor(private http: HttpClient) {}

    postEmailConfirmation(token: string, email: string, password: string) {
        const endpoint = environment.api.baseUrl + 'Accounts/confirm-email';
        const confirmation = {
            confirmationCode: token,
            email: email,
            password: password,
        };
        return this.http.post(endpoint, confirmation);
    }

    postEmailReset(token: string, userId: string) {
        const endpoint = environment.api.baseUrl + 'Accounts/reset-email';
        const confirmation = {
            confirmationCode: token,
            userId: userId,
        };
        return this.http.post(endpoint, confirmation);
    }

    postPasswordReset(token: string, email: string, password: string) {
        const endpoint = environment.api.baseUrl + 'Accounts/reset-password';
        const confirmation = {
            confirmationCode: token,
            email: email,
            password: password,
        };
        return this.http.post(endpoint, confirmation);
    }

    getUnconfirmedAccounts() {
        const endpoint = environment.api.baseUrl + 'Accounts/unconfirmed';
        return this.http.get(endpoint);
    }

    forgotPassword(email: string): Observable<null> {
        const endpoint =
      environment.api.baseUrl + 'Accounts/send-reset-password-email';
        return this.http.post<null>(endpoint, { email: email });
    }
    getNewPassword(id: number) {
        const endpoint =
      environment.api.baseUrl + `Accounts/get-password-for-member/${id}`;
        return this.http.post(endpoint, {});
    }

    resendConfirmations(users: Member[]) {
        const confirmations = [];
        users.forEach((user) => {
            confirmations.push({
                email: user.email,
            });
        });
        const endpoint =
      environment.api.baseUrl + 'Accounts/send-confirmation-email';
        return this.http.post(endpoint, confirmations);
    }
}
