import { MatSnackBar } from '@angular/material/snack-bar';
import { MembersService } from 'src/app/services/members.service';
import { Observable, Subject } from 'rxjs';
import { BadgeGrant, Badge } from '../../../../interfaces/badge.interface';
import { BadgesService } from 'src/app/services/badges.service';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { Member } from 'src/app/interfaces/member.interface';
import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
@Component({
    selector: 'app-member-badges',
    templateUrl: './member-badges.component.html',
    styleUrls: ['./member-badges.component.scss'],
})
export class MemberBadgesComponent implements OnInit, OnDestroy {
    @Input() member: Member;

    badgesReceived: BadgeGrant[] = [];
    badges: Badge[] = [];
    reloadSubject = new Subject<void>();
    dialogRef: MatDialogRef<unknown>;
    giveBadgeControl = new UntypedFormControl(null, Validators.required);

    constructor(
        private userInfoService: UserinfoService,
        private badgesService: BadgesService,
        private membersService: MembersService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {}
    ngOnDestroy() {
        this.reloadSubject.complete();
    }

    ngOnInit(): void {
        this.membersService.getMemberBadges(this.member.memberID).subscribe( x => {
            this.badgesReceived = x;
            console.log(x);
        });

        // Get ALL badges (this is only available for admin users)
        this.badgesService.getBadges().subscribe(data => {
            this.badges = data;
        }, error => {
            console.log(error);
        }); 
    }

    canDelete(right: string): Observable<boolean> {
        return this.userInfoService.hasRight(right);
    }
    deleteBadge(badge: Badge) {
        this.membersService
            .deleteMemberBadge(this.member.memberID, badge.badgeID)
            .subscribe(
                () => {
                    this.reloadSubject.next();
                    this.snackBar.open('Badge verwijderd!', 'OK', { duration: 3000 });
                },
                (error) => {
                    console.error(error);
                }
            );
    }
    grantBadge(): void {
        if (!this.giveBadgeControl.valid) {
            return;
        }
        const grant = {
            badgeID: this.giveBadgeControl.value,
            memberID: this.member.memberID,
        };
        this.membersService.postMemberBadge(grant).subscribe(
            () => {
                this.reloadSubject.next();
                this.giveBadgeControl.reset();
                this.snackBar.open('Badge toegekend!', 'OK', { duration: 3000 });
            },
            (error) => {
                this.snackBar.open(error.error, 'OK', { duration: 3000 });
            }
        );
    }
    doesContainBadge(
        badge: Badge,
        badgesReceived: BadgeGrant[]
    ) {
        return badgesReceived.some(
            (s) => s.badgeID === badge.badgeID
        );
    }

    openDialog(template: TemplateRef<unknown>, badge: BadgeGrant): void {
        this.dialogRef = this.dialog.open(template, { width: '500px', data: badge });
    }
}
