/* eslint-disable @typescript-eslint/no-explicit-any */
import { PipeTransform, Pipe } from '@angular/core';

/**
 * This pipe can be used to filter an array of objects
 */
@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    /**
   * Given a string path (e.g. 'group.groupType'), an object (e.g. a group of type Group)
   * and a separator (defaults to '.'), this method returns the value in the object at the
   * given path.
   * @param path
   * @param obj
   * @param separator
   */
    private resolve(path, obj = self, separator = '.') {
        const properties = Array.isArray(path) ? path : path.split(separator);
        return properties.reduce((prev, curr) => prev && prev[curr], obj);
    }

    /**
   * @param items
   * @param filters an object with keys to filter on and filter values
   */
    transform(items: any, filters: any, disjunctive = true): any {
        if (!filters || !items) {
            return items;
        }
        const keys = Object.keys(filters) as any[];
        const values = (Object.values(filters) as any[]).map(x =>
            x !== undefined ? (typeof x === 'string' ? x.toLowerCase() : x) : ''
        );
        return items.filter(item => {
            const keyCheckFunction = (key, i) => {
                const invalidValue = !values[i] || values[i] === '';
                const property = this.resolve(key, item);
                const valueIsInItem =
          property &&
          (typeof property === 'string'
              ? property.toLowerCase().indexOf(values[i]) > -1
              : property === values[i]);
                return invalidValue || valueIsInItem;
            };
            return disjunctive
                ? keys.some(keyCheckFunction)
                : keys.every(keyCheckFunction);
        });
    }
}
