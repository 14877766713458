import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { MembersService } from 'src/app/services/members.service';
import { Observable, Subject } from 'rxjs';
import {
    CertificateGrant,
    Certificate,
} from './../../../../interfaces/certificate.interface';
import { CertificatesService } from 'src/app/services/certificates.service';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { Member } from 'src/app/interfaces/member.interface';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-member-certificates',
    templateUrl: './member-certificates.component.html',
    styleUrls: ['./member-certificates.component.scss'],
})
export class MemberCertificatesComponent implements OnInit, OnDestroy {
    @Input() member: Member;

    certificatesReceived$: Observable<CertificateGrant[]>;
    certificates$: Observable<Certificate[]>;
    reloadSubject = new Subject<void>();

    giveCertControl = new UntypedFormControl(null, Validators.required);

    constructor(
        private userInfoService: UserinfoService,
        private certificatesService: CertificatesService,
        private membersService: MembersService,
        private snackBar: MatSnackBar
    ) {}
    ngOnDestroy() {
        this.reloadSubject.complete();
    }

    ngOnInit(): void {
        this.certificatesReceived$ = this.reloadSubject.pipe(
            startWith(''),
            switchMap(() =>
                this.membersService.getMemberCertificates(this.member.memberID)
            )
        );
        // Get the certificates and the users rights
        this.certificates$ = this.certificatesService.getCertificates().pipe(
            withLatestFrom(
                this.userInfoService.getUserInfo().pipe(map((u) => u.rights))
            ),
            map(([certificates, rights]) => {
                certificates = certificates.filter((certs) =>
                    rights.some((s) => s === certs.certificateRight)
                );
                return certificates;
            })
        );
    }

    canDelete(right: string): Observable<boolean> {
        return this.userInfoService.hasRight(right);
    }
    deleteCertificate(certificate: Certificate) {
        this.membersService
            .deleteMemberCertificate(this.member.memberID, certificate.certificateID)
            .subscribe(
                () => {
                    this.reloadSubject.next();
                    this.snackBar.open('Diploma verwijderd!', 'OK', { duration: 3000 });
                },
                (error) => {
                    console.error(error);
                }
            );
    }
    grantCertificate(): void {
        if (!this.giveCertControl.valid) {
            return;
        }
        const grant = {
            certificateID: this.giveCertControl.value,
            memberID: this.member.memberID,
        };
        this.membersService.postMemberCertificate(grant).subscribe(
            () => {
                this.reloadSubject.next();
                this.giveCertControl.reset();
                this.snackBar.open('Diploma toegekend!', 'OK', { duration: 3000 });
            },
            (error) => {
                this.snackBar.open(error.error, 'OK', { duration: 3000 });
            }
        );
    }
    doesContainCert(
        certificate: Certificate,
        certificatesReceived: CertificateGrant[]
    ) {
        return certificatesReceived.some(
            (s) => s.certificateID === certificate.certificateID
        );
    }
}
