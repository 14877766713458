import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Observable,
    BehaviorSubject,
} from 'rxjs';
import { Notification } from '../interfaces/notification.interface';
import { environment } from './../../environments/environment';
import { FormattingService } from './formatting.service';
import { tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

export interface FCMRegistration {
    fcmRegistrationID: number;
    fcmToken: string;
    created: string;
    metadata: string;
    currentBrowser?: boolean;
}

export interface PostNotification {
    title: string;
    description: string;
    memberTypes: 'all' | 'afroei' | 'registerdate';
    fromDate: string;
    action?: string;
}

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    unopened = new BehaviorSubject(0);

    constructor(
        private http: HttpClient,
        private formattingService: FormattingService,
        private titleService: Title
    ) {
        this.unopened.subscribe((unopened) => {
            const cleanTitle = this.titleService
                .getTitle()
                .replace(/^\(([1-9]+)\)/g, '');
            const newTitle = (unopened > 0 ? `(${unopened}) ` : '') + cleanTitle;
            this.titleService.setTitle(newTitle);
        });
    // if (this.browserSupportsFCM()) {
    //   // Get the initial token
    //   this.angularFireMessaging.tokenChanges.subscribe(
    //     token => (this.token = token),
    //     console.log
    //   );
    // }
    }

    /**
   * Gets the user notifications
   */
    getNotifications(): Observable<Notification[]> {
        const endpoint = environment.api.baseUrl + 'Notifications';
        return this.http.get<Notification[]>(endpoint).pipe(
            this.formattingService.mapMultipleUtcToLocal(['dateTime']),
            tap((notifications) => {
                this.unopened.next(notifications.filter((n) => !n.opened).length);
            })
        );
    }

    markNotificationsAsOpened(): Observable<Notification[]> {
        const endpoint = environment.api.baseUrl + 'Notifications/open';
        return this.http.put<Notification[]>(endpoint, {}).pipe(
            this.formattingService.mapMultipleUtcToLocal(['dateTime']),
            tap((notifications) => {
                this.unopened.next(notifications.filter((n) => !n.opened).length);
            })
        );
    }
    sendNotifications(model: PostNotification) {
        const endpoint = environment.api.baseUrl + 'Notifications/multiple';
        return this.http.post(endpoint, model);
    }

    // getFCMRegistrations(): Observable<FCMRegistration[]> {
    //   const endpoint = environment.api.baseUrl + 'Notifications/FCMRegistrations';
    //   const token$ = this.browserSupportsFCM()
    //     ? this.angularFireMessaging.getToken.pipe(catchError(() => of(undefined)))
    //     : of('no-token');
    //   return combineLatest(
    //     token$,
    //     this.http
    //       .get<FCMRegistration[]>(endpoint)
    //       .pipe(this.formattingService.mapMultipleUtcToLocal(['created']))
    //   ).pipe(
    //     map(([token, registrations]) => {
    //       return registrations.map(r => {
    //         return { ...r, currentBrowser: r.fcmToken === token };
    //       });
    //     })
    //   );
    // }

    // saveFCMRegistration(token: string): Observable<FCMRegistration> {
    //   const browser = browserInfo();
    //   const registration = {
    //     FCMToken: token,
    //     metadata: `${browser.name} op ${browser.os}`
    //   };
    //   const endpoint = environment.api.baseUrl + 'Notifications/FCMRegistrations';
    //   return this.http
    //     .post<FCMRegistration>(endpoint, registration)
    //     .pipe(this.formattingService.mapUtcToLocal(['created']));
    // }

    // deleteFCMRegistration(id: number): Observable<string> {
    //   const endpoint =
    //     environment.api.baseUrl + 'Notifications/FCMRegistrations/' + id;
    //   return this.http.delete<string>(endpoint);
    // }

    // requestBrowserPermission() {
    //   if (!this.browserSupportsFCM()) {
    //     return;
    //   }
    //   return this.angularFireMessaging.requestToken.pipe(
    //     switchMap(token => this.saveFCMRegistration(token))
    //   );
    // }

    // browserSupportsFCM(): boolean {
    //   return 'PushManager' in window && 'serviceWorker' in navigator;
    // }
}
