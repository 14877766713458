// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-tab-group {
  width: 100%;
}
mat-tab-group .tab-content {
  padding: 5px;
}
mat-tab-group .tab-content p {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/reaction/who-reacted-dialog/who-reacted-dialog.component.scss"],"names":[],"mappings":"AACA,2HAAA;AACA;EACE,WAAA;AAAF;AACE;EACE,YAAA;AACJ;AAAI;EACE,SAAA;AAEN","sourcesContent":["\r\n/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */\r\nmat-tab-group {\r\n  width: 100%;\r\n  .tab-content {\r\n    padding: 5px;\r\n    p {\r\n      margin: 0;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
