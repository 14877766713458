// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover-photo-holder {
  max-width: 400px;
}

.form-row {
  width: 100%;
}
.form-row mat-form-field {
  width: 100%;
  padding: 10px;
}
.form-row mat-form-field input {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/members/add-member/add-member.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,WAAA;AAEF;AADE;EACE,WAAA;EAEA,aAAA;AAEJ;AADI;EACE,WAAA;AAGN","sourcesContent":[".cover-photo-holder {\r\n  max-width: 400px;\r\n}\r\n.form-row{\r\n  width: 100%;\r\n  mat-form-field{\r\n    width: 100%;\r\n    \r\n    padding: 10px;\r\n    input{\r\n      width: 100%;\r\n    }\r\n  }\r\n  \r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
