import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Feedback } from '../interfaces/feedback.interface';

/**
 * This service contains all functions to call api/feedback
 */
@Injectable({ providedIn: 'root' })
export class FeedbackService {
    constructor(private http: HttpClient) {}

    /**
   * Post feedback to the API
   */
    postFeedback(feedback: Feedback) {
        const endpoint = environment.api.baseUrl + 'Feedback';
        return this.http.post(endpoint, feedback);
    }

    // TODO () this also needs pagination in the future
    getFeedback(){
        const endpoint = environment.api.baseUrl + 'Feedback';
        return this.http.get(endpoint);
    }

    putFeedback(id: number, feedback: Feedback){
        const endpoint = environment.api.baseUrl + 'Feedback/' + id;
        return this.http.put(endpoint, feedback);
    }
}
