import { ConfirmEmailResetPasswordComponent } from './components/confirm-email-reset-password/confirm-email-reset-password.component';

import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignOutComponent } from './components/sign-out/sign-out.component';
import { SignedInGuard } from './guards/signed-in.guard';
import { SignedOutGuard } from './guards/signed-out.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ResetEmailComponent } from './components/reset-email/reset-email.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./modules/dashboard/dashboard.module').then(
                (x) => x.DashboardModule
            ),
        canActivate: mapToCanActivate([SignedInGuard]),
    },
    {
        path: 'sign-in',
        component: SignInComponent,
        canActivate: mapToCanActivate([SignedOutGuard]),
    },
    {
        path: 'sign-out',
        component: SignOutComponent,
        canActivate: mapToCanActivate([SignedInGuard]),
    },
    {
        path: 'articles',
        loadChildren: () =>
            import('./modules/articles/articles.module').then(
                (x) => x.ArticlesModule
            ),
        canActivate: mapToCanActivate([SignedInGuard]),
    },
    {
        path: 'members',
        loadChildren: () =>
            import('./modules/members/members.module').then((x) => x.MembersModule),
        canActivate: mapToCanActivate([SignedInGuard]),
    },
    {
        path: 'albums',
        loadChildren: () =>
            import('./modules/photos/photos.module').then((x) => x.PhotosModule),
    // canActivate: mapToCanActivate([SignedInGuard]), // Anyone can access!
  },
  {
    path: "coupons",
    loadChildren: () =>
      import("./modules/coupons/coupons.module").then((x) => x.CouponsModule),
    canActivate: mapToCanActivate([SignedInGuard]),
  },
  {
    path: "groups",
    loadChildren: () =>
      import("./modules/groups/groups.module").then((x) => x.GroupsModule),
    canActivate: mapToCanActivate([SignedInGuard]),
  },
  {
    path: "events",
    loadChildren: () =>
      import("./modules/events/events.module").then((x) => x.EventsModule),
    canActivate: mapToCanActivate([SignedInGuard]),
  },
  {
    path: "matches",
    loadChildren: () =>
      import("./modules/matches/matches.module").then((x) => x.MatchesModule),
    canActivate: mapToCanActivate([SignedInGuard]),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./modules/admin/admin.module").then((x) => x.AdminModule),
    // TODO: this should also be guarded for Admins only
    canActivate: mapToCanActivate([SignedInGuard]),
  },
  {
    path: "reservables",
    loadChildren: () =>
      import("./modules/reservables/reservables.module").then(
        (x) => x.ReservablesModule
      ),
    canActivate: mapToCanActivate([SignedInGuard]),
  },
  {
    path: "rowing-ban",
    loadChildren: () =>
      import("./modules/rowing-ban/rowing-ban.module").then(
        (x) => x.RowingBanModule
      ),
    canActivate: mapToCanActivate([SignedInGuard]),
  },
  {
    path: "bkd",
    loadChildren: () =>
      import("./modules/bkd-schedule/bkd-schedule.module").then(
        (x) => x.BkdModule
      ),
      canActivate: mapToCanActivate([SignedInGuard])
  },
  {
    path: "confirm-email",
    component: ConfirmEmailResetPasswordComponent,
    canActivate: mapToCanActivate([SignedOutGuard]),
  },
  {
    path: "reset-email",
    component: ResetEmailComponent,
    canActivate: mapToCanActivate([SignedOutGuard]),
  },
  {
    path: "reset-password",
    component: ConfirmEmailResetPasswordComponent,
    canActivate: mapToCanActivate([SignedOutGuard]),
  },
  // The next two items are redirects for backward compatibility until the API sends mails with the new routes
  {
    path: "confirmemail",
    redirectTo: "confirm-email",
    pathMatch: "full",
  },
  {
    path: "passwordreset",
    redirectTo: "reset-password",
    pathMatch: "full",
  },
  { path: "404", component: NotFoundComponent },

  {
    path: "informationpage",
    loadChildren: () =>
      import("./modules/information/information.module").then(
        (x) => x.InformationModule
      ),
    canActivate: mapToCanActivate([SignedInGuard]),
  },

  {
    path: "records",
    loadChildren: () =>
      import("./modules/records/records.module").then(
        (x) => x.RecordsModule
      ),
    canActivate: mapToCanActivate([SignedInGuard]),
  },

  { path: "**", redirectTo: "/404" },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
