// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button .badge {
  display: inline-block;
  border-radius: 100px;
  background: #d81e05;
  color: white;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 3px;
  right: 3px;
}

mat-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
}

.notifications .empty {
  padding: 50px 20px;
  opacity: 0.5;
}
.notifications .notification {
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: rgb(225, 245, 255);
  transition: background 0.5s ease;
}
.notifications .notification p {
  margin: 10px 0 5px;
  font-size: 0.9rem;
  line-height: 1.2rem;
}
.notifications .notification small {
  opacity: 0.5;
}
.notifications .notification.with-action {
  cursor: pointer;
}
.notifications .notification.opened {
  background: transparent;
  opacity: 0.75;
}
.notifications .notification:last-child {
  border-bottom: 0;
}

::ng-deep .mat-mdc-menu-content {
  padding: 0 !important;
}

::ng-deep .mat-mdc-menu-panel {
  min-width: 200px !important;
  max-width: 400px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/notification-center/notification-center.component.scss"],"names":[],"mappings":"AAGE;EACE,qBAAA;EACA,oBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;AAFJ;;AAMA;EACE,gBAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;AAHF;;AAOE;EACE,kBAAA;EACA,YAAA;AAJJ;AAME;EACE,kBAAA;EACA,2CAAA;EACA,8BAAA;EACA,gCAAA;AAJJ;AAKI;EACE,kBAAA;EACA,iBAAA;EACA,mBAAA;AAHN;AAKI;EACE,YAAA;AAHN;AAKI;EACE,eAAA;AAHN;AAKI;EACE,uBAAA;EACA,aAAA;AAHN;AAKI;EACE,gBAAA;AAHN;;AAQA;EACE,qBAAA;AALF;;AAQA;EACE,2BAAA;EACA,2BAAA;AALF","sourcesContent":["@import '../../../palette.scss';\r\n\r\nbutton {\r\n  .badge {\r\n    display: inline-block;\r\n    border-radius: 100px;\r\n    background: orca-color('primary');\r\n    color: white;\r\n    width: 16px;\r\n    height: 16px;\r\n    line-height: 16px;\r\n    font-size: 12px;\r\n    font-weight: bold;\r\n    position: absolute;\r\n    top: 3px;\r\n    right: 3px;\r\n  }\r\n}\r\n\r\nmat-toolbar {\r\n  position: sticky;\r\n  top: 0;\r\n  left: 0;\r\n  z-index: 1000;\r\n}\r\n\r\n.notifications {\r\n  .empty {\r\n    padding: 50px 20px;\r\n    opacity: 0.5;\r\n  }\r\n  .notification {\r\n    padding: 10px 20px;\r\n    border-bottom: 1px solid rgba(0,0,0,0.1);\r\n    background: rgb(225, 245, 255);\r\n    transition: background .5s ease;\r\n    p {\r\n      margin: 10px 0 5px;\r\n      font-size: 0.9rem;\r\n      line-height: 1.2rem;\r\n    }\r\n    small {\r\n      opacity: 0.5;\r\n    }\r\n    &.with-action {\r\n      cursor: pointer;\r\n    }\r\n    &.opened {\r\n      background: transparent;\r\n      opacity: 0.75;\r\n    }\r\n    &:last-child {\r\n      border-bottom: 0;\r\n    }\r\n  }\r\n}\r\n\r\n::ng-deep .mat-mdc-menu-content {\r\n  padding: 0 !important;\r\n}\r\n\r\n::ng-deep .mat-mdc-menu-panel {\r\n  min-width: 200px !important;\r\n  max-width: 400px !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
