import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, ReplaySubject, iif } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tap, filter, take, switchMap, takeUntil } from 'rxjs/operators';
import { AuthToken } from '../interfaces/user-info.interface';
import * as moment from 'moment';
interface Token {
    token: string;
    okUntil: number;
    refresh_token: string;
    refresh_ok_until: string;
}
/**
 * This service handles the JWT token, makes authentication API calls and can be
 * used by components or other services to get the current authentication status.
 */

@Injectable({ providedIn: 'root' })
export class AuthService {
    private loggedInSubject = new ReplaySubject<boolean>(1);
    private currentToken = new BehaviorSubject<Token>(null);
    private refreshingSubject$ = new BehaviorSubject(false);
    private jwt = new JwtHelperService();
    constructor(private http: HttpClient) {
        console.log('Started Auth Service');
        const token = this.getToken();

        if (
            token &&
      token?.okUntil &&
      token?.refresh_ok_until &&
      token?.refresh_token &&
      token?.token &&
      moment(token.refresh_ok_until).isAfter(moment())
        ) {
            // We are still logged in.
            this.currentToken.next(token);

            this.loggedInSubject.next(true);
        } else {
            this.signOut();
        }
    }

    signIn(email: string, password: string): Observable<AuthToken> {
        const endpoint = environment.api.baseUrl + 'Auth/login';
        const credentials = {
            userName: email,
            password,
        };
        return this.http.post<AuthToken>(endpoint, credentials).pipe(
            tap(
                (res: AuthToken) => {
                    const token: Token = {
                        token: res.auth_token,
                        refresh_ok_until: res.refresh_expires_in,
                        refresh_token: res.refresh_token,
                        okUntil: res.expires_in,
                    };
                    this.loggedInSubject.next(true);
                    this.setToken(token);
                },
                () => {}
            )
        );
    }

    /**
   * Signs out a user by removing its access token
   */
    signOut() {
        const endpoint = environment.api.baseUrl + 'Auth/deleteToken';
        const token = this.getToken();

        if (token) {
            this.http
                .post(endpoint, { refreshToken: token.refresh_token })
                .subscribe();
        }
        this.removeToken();
        this.loggedInSubject.next(false);
        this.currentToken.next(null);
    }

    /**
   * Refresh on 401 error (take the stream and hold it and output the new token to all subscribers when refresh is given)
   */
    refreshTokenObs(): Observable<Token> {
        const endpoint = environment.api.baseUrl + 'Auth/refresh';
        const token = this.getToken();

        if (!this.refreshingSubject$.value) {
            this.refreshingSubject$.next(true);
            return of(token).pipe(
                switchMap((tok) => this.http.post<AuthToken>(endpoint, {
                    token: tok.token,
                    refreshToken: tok.refresh_token,
                })),
                switchMap((res: AuthToken) => {
                    const decoded = this.jwt.decodeToken(res.auth_token);
                    const tok: Token = {
                        token: res.auth_token,
                        okUntil: decoded.exp,
                        refresh_token: res.refresh_token,
                        refresh_ok_until: res.refresh_expires_in,
                    };
                    this.setToken(tok);
                    this.refreshingSubject$.next(false);

                    return of(tok);
                }),

                tap(
                    () => {},
                    () => {
                        this.signOut();
                    }
                )
            );
        } else {
            return this.refreshingSubject$.pipe(
                // Stop on error
                takeUntil(this.loggedInSubject.pipe(filter((f) => !f))),
                filter((t) => !t),
                switchMap(() => this.currentToken.asObservable()),
                take(1)
            );
        }
    }

    /**
   * Takes a role string or array of strings and returns a boolean that
   * indicates whether the current user has that role or one of those roles
   * @param queriedRole the role or roles the user should have
   * @param conjunctive if this is true and an array of roles is passed, the
   * user must have them all
   */
    hasRole(queriedRole: string | string[], conjunctive = false): boolean {
        const decodedToken = this.jwt.decodeToken(this.getToken().token);
        const role: string | string[] =
      decodedToken[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      ];
        // Is the user role a string?
        if (typeof role === 'string') {
            // If the queried role a string?
            if (typeof queriedRole === 'string') {
                // Option 1: Comparing string with string
                return role === queriedRole;
            } else if (!conjunctive) {
                // Option 2: Comparing string with array (disjunctive):
                // check if queriedRoles have the current role
                return queriedRole.includes(role);
            } else {
                // Option 3: comparing string with array (conjunctive)
                // This can only be true if the queried roles array has just one item
                return queriedRole.length === 1 && queriedRole[0] === role;
            }
        } else {
            // If the queried role a string?
            if (typeof queriedRole === 'string') {
                // Option 4: Comparing array with string
                return role.includes(queriedRole);
            } else if (!conjunctive) {
                // Option 5: Comparing array with array (disjunctive):
                // The queried list should have some items that are in the user's roles list
                return queriedRole.some((r) => role.includes(r));
            } else {
                // Option 6: comparing array with array (conjunctive)
                // This can only be true if the queried roles array has just one item
                return queriedRole.every((r) => role.includes(r));
            }
        }
    }
    get userLoggedIn(): Observable<boolean> {
    // Return true only if the currentToken is not null (stopping race conditions)
        return this.loggedInSubject.pipe(
            switchMap((t) =>
                iif(
                    () => t,
                    this.currentToken.pipe(
                        filter((t) => t !== null),
                        switchMap(() => of(true))
                    ),
                    of(false)
                )
            )
        );
    }

    setToken(token: Token) {
        this.currentToken.next(token);
        localStorage.setItem('jwt-token', JSON.stringify(token));
    }
    removeToken() {
        localStorage.removeItem('jwt-token');
    }
    getCurrentToken() {
        return this.currentToken.value;
    }
    getToken(): Token {
        const t = JSON.parse(localStorage.getItem('jwt-token')) as Token;
        // console.log(t);
        return t;
    }

    isRefreshStillGood(): boolean {
        const token = this.getToken();
        if (!token) {
            return false;
        }
        return moment(token.refresh_ok_until).isAfter(moment());
    }
}
