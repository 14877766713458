import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { ArticleVote } from 'src/app/interfaces/article.interface';
import { CommentVote } from 'src/app/interfaces/comment.interface';
import { ArticlesService } from 'src/app/services/articles.service';
import { CommentsService } from 'src/app/services/comments.service';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { WhoReactedDialogComponent } from './who-reacted-dialog/who-reacted-dialog.component';

interface VoteButton {
    value: string;
    normalizedName: string;
    icon: string;
}

@Component({
    selector: 'app-reaction',
    templateUrl: './reaction.component.html',
    styleUrls: ['./reaction.component.scss'],
})
export class ReactionComponent implements OnInit {
    @Input()
        votes: { [key: string]: ArticleVote[] };

    @Input()
        voteType: 'comment' | 'article' = 'article';

    @Input()
        articleID: number;

    @Input()
        showReactButton = false;
    @Input()
        showBanButton = false;
    @Input()
        bannedComment = false;

    @Input()
        timePosted: string;

    @Input()
        commentID: number;

    @Input()
        myVote: ArticleVote | CommentVote;

    @Output()
        reactClicked = new EventEmitter();
    @Output()
        banClicked = new EventEmitter();

    @Output()
        newVote: EventEmitter<ArticleVote> = new EventEmitter();

    @Output()
        removeMyVote = new EventEmitter();

    voteButtons: VoteButton[] = [
        {
            value: 'Knor',
            normalizedName: 'knor',
            icon: '/assets/votes/knor.svg',
        },

        {
            value: 'Gesodemieter',
            normalizedName: 'gesodemieter',
            icon: '/assets/votes/gesodemieter.svg',
        },
        {
            value: 'Sip',
            normalizedName: 'sip',
            icon: '/assets/votes/sip.svg',
        },
        {
            value: 'Spetter',
            normalizedName: 'spetter',
            icon: '/assets/votes/spetter.svg',
        },
        {
            value: 'Proost',
            normalizedName: 'proost',
            icon: '/assets/votes/proost.svg',
        },

        {
            value: 'Allez',
            normalizedName: 'allez',
            icon: '/assets/votes/allez.svg',
        },
    ];
    memberID: number;
    voteLength: number;

    constructor(
        private userInfo: UserinfoService,
        private articleService: ArticlesService,
        private commentService: CommentsService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.userInfo
            .getUserID()
            .pipe(take(1))
            .subscribe((i) => {
                this.memberID = i;
            });
        this.voteSum();
    }
    voteSum() {
        if (this.votes) {
            this.voteLength = Object.keys(this.votes)
                .map((k) => this.votes[k].length)
                .reduce((a, b) => a + b, 0);
        } else {
            this.voteLength = 0;
            return 0;
        }

        return this.voteLength;
    }

    openDialog() {
        const _dialogRef = this.dialog.open(WhoReactedDialogComponent, {
            data: {
                voteButtons: this.voteButtons,
                votes: this.votes,
            },
            panelClass: 'no-padding-dialog',
        });
    }

    findVote(normName: string): VoteButton {
        return this.voteButtons.filter((a) => a.normalizedName === normName)[0];
    }
    getVoteNames() {
        if (this.votes) {
            return Object.keys(this.votes)
                .map((k) =>
                    this.votes[k]
                        .map((v) => v.memberName)

                        .reduce((a, b) => a + ' \n ' + b, '')
                )
                .reduce((a, b) => a + b, '');
        } else {
            return '';
        }
    }
    getVote(vote: ArticleVote[]) {
        return vote.map((v) => v.memberName).reduce((a, b) => a + ' \n ' + b, '');
    }

    addVote(vote: VoteButton) {
        if (this.articleID && this.voteType === 'article') {
            this.articleService
                .voteArticle(this.articleID, vote.normalizedName)
                .subscribe(async () => {
                    this.snackBar.open('Gestemd', 'ok', { duration: 2000 });

                    this.newVote.emit({
                        voteType: vote.normalizedName,
                        memberName: 'ik',
                        memberID: this.memberID,
                    });
                });
        } else if (this.commentID && this.voteType === 'comment') {
            this.commentService
                .voteComment(this.commentID, vote.normalizedName)
                .subscribe(async () => {
                    this.snackBar.open('Gestemd', 'ok', { duration: 2000 });

                    this.newVote.emit({
                        voteType: vote.normalizedName,
                        memberName: 'ik',
                        memberID: this.memberID,
                    });
                });
        }
    }

    removeVote() {
        if (this.articleID && this.myVote) {
            this.articleService
                .removeVoteArticle(this.articleID)
                .subscribe(async () => {
                    this.voteSum();
                    this.snackBar.open('Stem Verwijderd', 'ok', { duration: 2000 });

                    this.removeMyVote.emit();
                });
        } else if (this.commentID && this.myVote && this.voteType === 'comment') {
            this.commentService
                .removeVoteComment(this.commentID)
                .subscribe(async () => {
                    this.voteSum();
                    this.snackBar.open('Stem Verwijderd', 'ok', { duration: 2000 });

                    this.removeMyVote.emit();
                });
        }
    }

    // async showVotePop() {
    //   const buttonVote = this.voteButtons.map((v) => {
    //     return {
    //       text: v.value,
    //       icon: v.icon,
    //       handler: () => {
    //         this.addVote(v);
    //       },
    //     };
    //   });

    //   const popover = await this.actionSheetController.create({
    //     header: "Reageren",
    //     buttons: [
    //       ...buttonVote,
    //       {
    //         text: "Annuleer",
    //         icon: "close",
    //         role: "cancel",
    //       },
    //     ],
    //   });
    //   return await popover.present();
    // }
}
