import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { MomentModule } from 'ngx-moment';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmEmailResetPasswordComponent } from './components/confirm-email-reset-password/confirm-email-reset-password.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NotificationCenterComponent } from './components/notification-center/notification-center.component';
import { ResetEmailComponent } from './components/reset-email/reset-email.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignOutComponent } from './components/sign-out/sign-out.component';
import { MembersSharedModule } from './modules/members/members-shared.module';
import { SharedModule } from './modules/shared/shared.module';
import { QrCodeReaderService } from './services/qr-code-reader.service';
import { UnauthorizedRequestInterceptor } from './services/unauthorized-request.interceptor';
import { WebsocketService } from './services/websocket.service';
import { AuthService } from './services/auth.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';


/**
 * This is the AppModule. Here everything comes together.
 */
export function jwtOptionsFactory(authService: AuthService) {
    return {
        tokenGetter: () => authService.getCurrentToken()?.token,
        allowedDomains: [environment.api.domain],
        headerName: 'Authorization',
        authScheme: 'Bearer ',
    };
}
// register nl locale
registerLocaleData(localeNl, 'nl');

@NgModule({
    declarations: [
        NotFoundComponent,
        AppComponent,
        FooterComponent,
        SignInComponent,
        SignInComponent,
        SignOutComponent,
        ConfirmEmailResetPasswordComponent,
        ResetEmailComponent,
        NotificationCenterComponent
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        LoadingBarHttpClientModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatCardModule,
        
        // Enable this to also show the loading bar when changing route:
        LoadingBarRouterModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [AuthService],
            },
        }),
        MomentModule,
        SharedModule,
        ServiceWorkerModule.register('combined-sw.js', {
            enabled: environment.production,
        }),
        BrowserModule,
        MembersSharedModule,
        MatButtonModule
    ],
    providers: [
        AuthService,
        WebsocketService,
        // Set locale for angular
        { provide: LOCALE_ID, useValue: 'nl' },
        QrCodeReaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedRequestInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

/**
 * a token getter function is needed for auth0/angular2-jwt. It needs to be
 * an exported function for AOT compilation, which we use.
 */
export function tokenGetter() {
    return JSON.parse(localStorage.getItem('jwt-token'))?.token;
}
