import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Badge } from './../interfaces/badge.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BadgesService {
    constructor(private http: HttpClient) {}

    /**
   * Get all possible badges
   */
    getBadges(): Observable<Badge[]> {
    // Build the API endpoint URL
        const endpoint = environment.api.baseUrl + 'badges';
        // Construct the observable we want the data from
        const observable = this.http.get<Badge[]>(endpoint);

        return observable;
    }

    /**
   * Get a badge by its ID
   * @param id
   */
    getBadgeById(id: number): Observable<Badge> {
    // Build the API endpoint URL
        const endpoint = environment.api.baseUrl + 'badges/' + id;
        // Construct the observable we want the data from
        const observable = this.http.get<Badge>(endpoint);

        return observable;
    }
}
