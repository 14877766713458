import { InformationPage } from 'src/app/interfaces/information.interface';
import { FormattingService } from "./formatting.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MemberDocument } from "../interfaces/document.interface";


@Injectable({ providedIn: "root" })
export class InformationService {

  constructor(
    private http: HttpClient,
    private formattingService: FormattingService
  ) {} // TODO de formattingService gebruiken we nu niet

  postInformationPage(informationPage : InformationPage){
    let endpoint = environment.api.baseUrl + "Information/post";
    return this.http.post<string>(endpoint, informationPage);
  }

  editInformationPage(informationPage : InformationPage, slug : string){
    let endpoint = environment.api.baseUrl + "Information/" + slug;
    return this.http.put<string>(endpoint, informationPage);
  }

  deleteInformationPage(slug : string){
    let endpoint = environment.api.baseUrl + "Information/"+ slug;
    return this.http.delete(endpoint);
  }

  getInformationPage(title){
    let endpoint = environment.api.baseUrl + "Information/" + title;
    return this.http.get<InformationPage>(endpoint);
  }

  getInformationNavBar(){
    let endpoint = environment.api.baseUrl + "Information/NavBar";
    let informationpages = this.http.get<InformationPage[]>(endpoint);

    return informationpages;
  }
}
