import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Member } from 'src/app/interfaces/member.interface';

@Component({
    selector: 'app-member-name',
    templateUrl: 'member-name.component.html',
    styleUrls: [],
})
export class MemberNameComponent implements OnChanges {
    isBirthday = false;

    @Input() member: Member;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.member) {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);
            if (changes.member?.currentValue?.birthdate) {
                const birthdate = new Date(changes.member.currentValue.birthdate);
                birthdate.setHours(0);
                birthdate.setMinutes(0);
                birthdate.setSeconds(0);
                birthdate.setMilliseconds(0);
                birthdate.setFullYear(today.getFullYear());
                this.isBirthday = +today === +birthdate;
            }
        }
    }
}
