import { BtnIconAgRendererComponent } from './../btn-icon-ag-renderer/btn-icon-ag-renderer.component';
import { Type } from './../../../../interfaces/type.interface';
import { take } from 'rxjs/operators';
import { TypesService } from './../../../../services/types.service';
import {
    Component,
    OnInit,
    Input,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IRowNode } from 'ag-grid-community';

@Component({
    selector: 'app-questions-form',
    templateUrl: './questions-form.component.html',
    styleUrls: ['./questions-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionsFormComponent),
            multi: true,
        },
    ],
})
export class QuestionsFormComponent implements OnInit, ControlValueAccessor {
    @Input()
        newEvent = true;
    frameworkComponents = {
        btnIconAgRendererComponent: BtnIconAgRendererComponent,
    };

    columnDefs = [
        { headerName: 'VraagNr', field: 'calendarEventQuestionID' },
        {
            headerName: 'Vraag*',
            field: 'questionText',
            editable: true,
            minWidth: 400,
            valueFormatter: (params) =>
                params.value ? params.value : 'Typ je vraag...',
        },
        {
            headerName: 'Type antwoord*',
            field: 'questionType',
            editable: this.canEdit.bind(this),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: this.getTypesArray.bind(this),

            valueFormatter: (params) =>
                params.value ? params.value.value : 'Dubbelklik om aan te passen',
        },
        {
            headerName: 'Verplicht veld?',
            field: 'requiredAnswer',
            editable: this.canEdit.bind(this),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: [true, false],
            },
            valueFormatter: (params) => (params.value ? 'ja' : 'nee'),
        },
        {
            headerName: 'Verwijderen',
            field: 'calendarEventQuestionID',
            cellRenderer: 'btnIconAgRendererComponent',
            cellRendererParams: {
                clicked: this.deleteRow.bind(this),
                disabled: (p) => (p === '' ? false : true),
            },
        },
    ];
    rowData = [];

    types: Type[];
    private _onChange;

    private gridApi;
    private _gridColumnApi;

    constructor(private typesService: TypesService) {}

    ngOnInit(): void {
        this.typesService
            .getTypes('EventQuestionType')
            .pipe(take(1))
            .subscribe((t) => {
                this.types = t;
            });
    }
    onGridReady(params) {
        this.gridApi = params.api;
        this._gridColumnApi = params.columnApi;
    }
    writeValue(questions) {
        this.rowData = questions;
    }
    registerOnChange(change) {
        this._onChange = change;
    }
    registerOnTouched(_change) {}
    onChange(_event) {
        const items: Array<IRowNode> = [];
        this.gridApi.forEachNode(function (node) {
            items.push(node.data);
        });
        this._onChange(items);
    }
    canEdit(params) {
        if (params.node.data.calendarEventQuestionID === '') {
            return true;
        }
        return false;
    }

    private getTypesArray() {
        return {
            values: this.types,
        };
    }
    deleteRow(params) {
        this.gridApi.applyTransaction({ remove: [params.data] });
        const items: Array<IRowNode> = [];
        this.gridApi.forEachNode(function (node) {
            items.push(node.data);
        });
        this._onChange(items);
    }
    addRow() {
        this.gridApi.applyTransaction({
            add: [
                {
                    calendarEventQuestionID: '',
                    questionText: '',
                    questionTypeNorm: '',
                    requiredAnswer: false,
                },
            ],
        });
        const items: Array<IRowNode> = [];
        this.gridApi.forEachNode(function (node) {
            items.push(node.data);
        });
        this._onChange(items);
    }
    allQuestionsValid() {
        return !this.rowData.some((c) => !c.questionText || !c.questionType);
    }
}
