import { Router } from '@angular/router';
import { Member } from '../../../interfaces/member.interface';
import { MembersService } from '../../../services/members.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { filter, switchMap } from 'rxjs/operators';

/**
 * Component to show an overview of all members
 */
@Component({
    selector: 'app-member-birthdays',
    templateUrl: 'member-birthdays.component.html',
    styleUrls: ['member-birthdays.component.scss'],
})
export class MemberBirthdayComponent implements OnInit {
    birthdayMembers: {
        member: Member;
        fromNowLabel: string;
    }[];

    today = new Date();

    constructor(
        private membersService: MembersService,
        private userInfoService: UserinfoService,
        private router: Router
    ) {}

    ngOnInit() {
        this.userInfoService
            .hasRight('GetMembers')
            .pipe(
                filter((r) => r),
                switchMap(() => this.membersService.getBirthdayMembers(2))
            )
            .subscribe((members: Member[]) => {
                this.birthdayMembers = members
                    .map((member) => {
                        const time = this.membersService.timeUntilBirthday(member);
                        return {
                            member,
                            time,
                        };
                    })
                    .sort((a, b) => a.time - b.time)
                    .map(({ member, time }) => {
                        let fromNowLabel = '';
                        if (time > 0) {
                            fromNowLabel = moment().add(time).fromNow();
                        }
                        return {
                            member,
                            fromNowLabel,
                        };
                    });
            });
    }

    goToMember(member: Member) {
        this.router.navigate(['members', member.memberID]);
    }
}
