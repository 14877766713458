import { CommentSet } from './../interfaces/comment.interface';
import { FormattingService } from './formatting.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Comment } from '../interfaces/comment.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';

/**
 * This service contains all functions to call api/comments
 */
@Injectable({ providedIn: 'root' })
export class CommentsService {
    constructor(
        private http: HttpClient,
        private formattingService: FormattingService,
        private location: Location
    ) {}

    // Get comments for a given CommentSet
    getComments(id: number): Observable<CommentSet> {
        const endpoint = `${environment.api.baseUrl}Comments/${id}`;
        return this.http.get<CommentSet>(endpoint).pipe(
            map((commentSet) => ({
                ...commentSet,
                comments: commentSet.comments.map((comment) => ({
                    ...comment,
                    datePosted: this.formattingService.convertUtcToLocal(
                        comment.datePosted
                    ),
                })),
            }))
        );
    }

    // Post new comment
    postComment(comment: Comment): Observable<Comment> {
        const path = this.location.path().split('?')[0];
        comment = { ...comment, postedOnPage: path };

        const endpoint = `${environment.api.baseUrl}Comments`;
        // datePosted is set on the backend so no conversion to UTC is needed
        return this.http.post<Comment>(endpoint, comment);
    }

    // ban comments
    deleteComment(id: number): Observable<Comment> {
        const endpoint = `${environment.api.baseUrl}Comments/${id}`;
        return this.http
            .delete<Comment>(endpoint)
            .pipe(this.formattingService.mapUtcToLocal(['datePosted']));
    }
    voteComment(id: number, voteType: string) {
        const endpoint = environment.api.baseUrl + 'Comments/vote';

        return this.http.post(endpoint, { commentID: id, voteType: voteType });
    }

    removeVoteComment(id: number) {
        const endpoint = environment.api.baseUrl + 'Comments/vote/' + id;

        return this.http.delete(endpoint);
    }
    banComment(id: number) {
        const endpoint = environment.api.baseUrl + 'Comments/' + id;
        return this.http.delete(endpoint);
    }
}
