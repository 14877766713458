// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#badge {
  color: white !important;
  cursor: pointer;
}

#badge > mat-icon {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/member-badges/member-badges.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,eAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":["#badge {\r\n    color: white !important;\r\n    cursor: pointer;\r\n}\r\n\r\n#badge > mat-icon {\r\n    margin-right: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
