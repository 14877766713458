import {
    Component,
    Inject
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArticleVote } from 'src/app/interfaces/article.interface';
import { CommentVote } from 'src/app/interfaces/comment.interface';
interface VoteButton {
    value: string;
    normalizedName: string;
    icon: string;
}

@Component({
    selector: 'app-who-reacted',
    templateUrl: './who-reacted-dialog.component.html',
    styleUrls: ['./who-reacted-dialog.component.scss'],
})
export class WhoReactedDialogComponent {
    votes: { [key: string]: ArticleVote[] | CommentVote[] };

    voteButtons: VoteButton[];

    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        this.votes = data.votes;
        this.voteButtons = data.voteButtons;
    }

    findVote(normName: string) {
        return this.voteButtons.filter((a) => a.normalizedName === normName)[0];
    }
}
