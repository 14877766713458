export const environment = {
  production: false,
  api: {
    domain: "develop-api.orcaroeien.nl",
    baseUrl: "https://develop-api.orcaroeien.nl/api/",
    imageUrl: "https://orcaroeienapi.blob.core.windows.net/images/",

    websocket: "wss://",
  },
  firebase: {
    apiKey: "AIzaSyBUPut3Tl2MlGa7edUhDBxTMS1_E-Ia4p8",
    authDomain: "ledenportaal-angular.firebaseapp.com",
    databaseURL: "https://ledenportaal-angular.firebaseio.com",
    projectId: "ledenportaal-angular",
    storageBucket: "ledenportaal-angular.appspot.com",
    messagingSenderId: "163233839123",
  },
};
