import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, switchMap } from 'rxjs/operators';
import { Feedback } from '../../../../interfaces/feedback.interface';
import { FeedbackService } from '../../../../services/feedback.service';

@Component({
    selector: 'app-add-feedback',
    templateUrl: './add-feedback.component.html'
})
export class AddFeedbackComponent {
    feedback: Feedback = {
        status: 'open',
        message: ''
    };

    @ViewChild('feedbackDialog', { read: TemplateRef }) feedbackDialog;

    constructor(
        private dialog: MatDialog,
        private feedbackService: FeedbackService,
        private snackBar: MatSnackBar
    ) {}

    openFeedbackDialog() {
        const dialogRef = this.dialog.open(this.feedbackDialog, {
            width: '400px'
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(() => this.feedback.message !== ''),
                switchMap(() => this.feedbackService.postFeedback(this.feedback))
            )
            .subscribe(
                () => {
                    this.snackBar.open('Feedback verstuurd', 'OK', {
                        duration: 5000
                    });
                    this.feedback.message = '';
                },
                error => {
                    console.error(error);
                }
            );
    }
}
