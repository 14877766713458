import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, limit = 25, completeWords = true, ellipsis = '...') {
        if (completeWords && value.length > limit) {
            limit = value.substr(0, limit).lastIndexOf(' ');
        }
        if (value.length <= limit) {
            ellipsis = '';
        }
        return `${value.substr(0, limit)}${ellipsis}`;
    }
}
