import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class WebsocketService {
    constructor() {}

    private subject: Subject<MessageEvent>;

    public connect(url): Observable<MessageEvent> {
        console.log(this.subject);
        if (!this.subject) {
            this.subject = this.create(url);
        }
        return this.subject.asObservable();
    }

    private create(url): Subject<MessageEvent> {
        const ws = new WebSocket(url);
        
        const subject = new Subject<MessageEvent>();

        ws.onmessage = subject.next.bind(subject);
        ws.onerror = subject.error.bind(subject);
        ws.onclose = subject.complete.bind(subject);

        subject.subscribe((data: unknown) => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify(data));
            }
        });
        return subject;
    }

    disconnect(){
        this.subject.complete();
        this.subject = undefined;
    }
}
