// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
             mat-form-field {
               background-color: #eee;
             }
         
             .container {
               width: 350px;
             }
             `, "",{"version":3,"sources":["webpack://./src/app/modules/shared/components/datepicker-ag-grid/datepicker-ag-grid.component.ts"],"names":[],"mappings":";aACa;eACE,sBAAsB;aACxB;;aAEA;eACE,YAAY;aACd","sourcesContent":["\n             mat-form-field {\n               background-color: #eee;\n             }\n         \n             .container {\n               width: 350px;\n             }\n             "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
