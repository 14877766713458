import { Validators } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarEvent } from 'src/app/interfaces/calendar-event.interface';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
    selector: 'app-extra-tickets-dialog',
    templateUrl: './extra-tickets-dialog.component.html',
    styleUrls: ['./extra-tickets-dialog.component.scss'],
})
export class ExtraTicketsDialogComponent implements OnInit {
    event: CalendarEvent;

    form = new UntypedFormControl(1, Validators.required);

    constructor(
    @Inject(MAT_DIALOG_DATA) data,
        private dialogRef: MatDialogRef<ExtraTicketsDialogComponent>
    ) {
        this.event = data.event;
    }

    ngOnInit(): void {
        this.form.setValidators([
            Validators.required,
            Validators.min(1),
            Validators.max(
                this.event.maxNumberTicketsPerSubscription -
          this.event.mySubscription.numberOfTickets
            ),
        ]);
    }
    submit() {
        this.dialogRef.close({
            numberOfTickets: this.form.value,
        });
    }
}
