// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  div.data-title {
    font-weight: bold;
    margin: 5px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  
  div.data {
    margin: 5px;
    margin-top: 0px;
    white-space: pre-line
  }
  
  div.member-photo-holder img {
    border-radius: 100px;
    width: 50px;
    height: 50px;
    margin: 0px;
  }
  
  .media {
    cursor: pointer;
    width: 100%;
    margin: 10px 0;
  }
 
  .special {
    color: #d81e05;
  }

  /*@media (min-width: 800px) {
    .media {
        width: 225px;
        margin-bottom: 10px;
        margin-right: 5px;
    }
  }*/`, "",{"version":3,"sources":["webpack://./src/app/modules/members/member-summary/member-summary.component.css"],"names":[],"mappings":"EAAE;IACE,iBAAiB;IACjB,WAAW;IACX,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,eAAe;IACf;EACF;;EAEA;IACE,oBAAoB;IACpB,WAAW;IACX,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,eAAe;IACf,WAAW;IACX,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;;;;;;IAME","sourcesContent":["  div.data-title {\r\n    font-weight: bold;\r\n    margin: 5px;\r\n    margin-bottom: 0px;\r\n    margin-top: 0px;\r\n  }\r\n  \r\n  div.data {\r\n    margin: 5px;\r\n    margin-top: 0px;\r\n    white-space: pre-line\r\n  }\r\n  \r\n  div.member-photo-holder img {\r\n    border-radius: 100px;\r\n    width: 50px;\r\n    height: 50px;\r\n    margin: 0px;\r\n  }\r\n  \r\n  .media {\r\n    cursor: pointer;\r\n    width: 100%;\r\n    margin: 10px 0;\r\n  }\r\n \r\n  .special {\r\n    color: #d81e05;\r\n  }\r\n\r\n  /*@media (min-width: 800px) {\r\n    .media {\r\n        width: 225px;\r\n        margin-bottom: 10px;\r\n        margin-right: 5px;\r\n    }\r\n  }*/"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
