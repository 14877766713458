import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * This service handles general layout business such as hiding/showing the footer.
 */
@Injectable({ providedIn: 'root' })
export class LayoutService {
    // The current status of the footer is represented using this BehaviourSubject
    footerVisibility: BehaviorSubject<boolean>;
    headerVisibility: BehaviorSubject<boolean>;
    fullWidth: BehaviorSubject<boolean>;
    blackBG: BehaviorSubject<boolean>;
    backdropVisibility: BehaviorSubject<boolean>;
    constructor() {
        this.footerVisibility = new BehaviorSubject<boolean>(true);
        this.headerVisibility = new BehaviorSubject<boolean>(true);
        this.fullWidth = new BehaviorSubject<boolean>(false);
        this.blackBG = new BehaviorSubject<boolean>(false);
        this.backdropVisibility = new BehaviorSubject<boolean>(false);
    }

    /**
   * The the footer status to hidden
   */
    hideFooter() {
        this.footerVisibility.next(false);
    }

    showFullWidth() {
        this.fullWidth.next(true);
    }
    partialWidth() {
        this.fullWidth.next(false);
    }
    showBackdrop() {
        this.backdropVisibility.next(true);
    }
    hideBackdrop() {
        this.backdropVisibility.next(false);
    }

    showBlackBG() {
        setTimeout(() => {
            this.blackBG.next(true);
        });
    }
    hideBlackBG() {
        this.blackBG.next(false);
    }

    /**
   * The the footer status to shown
   */
    showFooter() {
        this.footerVisibility.next(true);
    }

    /**
   * The the footer status to hidden
   */
    hideHeader() {
        this.headerVisibility.next(false);
    }

    /**
   * The the footer status to shown
   */
    showHeader() {
        this.headerVisibility.next(true);
    }
}
