// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card {
  margin: 10px auto;
}
@media only screen and (min-width: 599px) {
  mat-card {
    margin: 75px auto;
    width: 400px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/sign-in/sign-in.component.scss"],"names":[],"mappings":"AACA,2HAAA;AACA;EACE,iBAAA;AAAF;AACE;EAFF;IAGI,iBAAA;IACA,YAAA;EAEF;AACF","sourcesContent":["\r\n/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */\r\nmat-card {\r\n  margin: 10px auto;\r\n  @media only screen and (min-width: 599px) {\r\n    margin: 75px auto;\r\n    width: 400px;\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
