import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Can help with determining whether there is a connection to the internet
 */
@Injectable({
    providedIn: 'root'
})
export class ConnectionService {
    private _isOnline: Observable<boolean>;

    constructor() {
        this._isOnline = new Observable(observer => {
            window.addEventListener('offline', () => {
                observer.next(false);
            });
            window.addEventListener('online', () => {
                observer.next(true);
            });
        });
    }

    /**
   * Returns an observable boolean that represents the connection status
   */
    isOnline(): Observable<boolean> {
        return this._isOnline;
    }
}
