import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
    MatNativeDateModule,
    MatRippleModule,
    MAT_DATE_FORMATS,
    DateAdapter,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';

import { ImageCropperModule } from 'ngx-image-cropper';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MomentModule } from 'ngx-moment';
import { AddFeedbackComponent } from './components/add-feedback/add-feedback.component';
import { DateTimePickerComponent } from './components/datetime-picker/datetime-picker.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { FabMenuComponent } from './components/fab-menu/fab-menu.component';
import { ImagePickerComponent } from './components/image-picker/image-picker.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { HyvesEmoji } from './pipes/hyves-emoji.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

import { AgGridModule } from 'ag-grid-angular';
import { DatePickerAgGridComponent } from './components/datepicker-ag-grid/datepicker-ag-grid.component';
import {
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter,
    MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { TimeSpanInputComponent } from './components/time-span-input/time-span-input.component';
import {
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { SearchArticleComponent } from './components/search-article/search-article.component';
import { QuestionsDialogComponent } from './components/questions-dialog/questions-dialog.component';
import { AddTagComponent } from './components/add-tag/add-tag.component';
import { ReactionComponent } from './components/reaction/reaction.component';
import { WhoReactedDialogComponent } from './components/reaction/who-reacted-dialog/who-reacted-dialog.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { MyDocumentsComponent } from './components/documents/my-documents/my-documents.component';
import { AngularIbanModule } from 'angular-iban';
import { MemberCertificatesComponent } from './components/member-certificates/member-certificates.component';
import { MemberBadgesComponent } from './components/member-badges/member-badges.component';
import { MemberNameComponent } from './components/member-name/member-name.component';
import { QuestionsFormComponent } from './components/questions-form/questions-form.component';
import { ToggleBtnAgRendererComponent } from './components/toggle-btn-ag-renderer/toggle-btn-ag-renderer.component';
import { BtnIconAgRendererComponent } from './components/btn-icon-ag-renderer/btn-icon-ag-renderer.component';
import { ExtraTicketsDialogComponent } from './components/extra-tickets-dialog/extra-tickets-dialog.component';
import { WeatherComponent } from './components/weather/weather.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ImageCropperModule,
        ReactiveFormsModule,
        MomentModule,
        MarkdownModule.forRoot({
            // optional, only if you use [src] attribute
            markedOptions: {
                provide: MarkedOptions,
            },
        }),
        /** Material shizzle */
        MatExpansionModule,
        MatChipsModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        FlexLayoutModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatGridListModule,
        MatPaginatorModule,
        MatIconModule,
        MatToolbarModule,
        MatTabsModule,
        MatBadgeModule,
        MatRadioModule,
        AngularIbanModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatSelectModule,
        MatMenuModule,
        MatDividerModule,
        MatSidenavModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSlideToggleModule,
        MatListModule,
        MatSnackBarModule,
        MatSortModule,
        MatTooltipModule,
        MatTableModule,
        MatSliderModule,
        MatRippleModule,
        MatProgressBarModule,
        NgxMaterialTimepickerModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        MatMomentDateModule,
        AgGridModule,
        NgxMatNativeDateModule,
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        FilterPipe,
        HyvesEmoji,
        DatePipe,
        CapitalizePipe,
        TruncatePipe,
    ],
    declarations: [
        ToggleBtnAgRendererComponent,
        BtnIconAgRendererComponent,
        MemberNameComponent,
        DatePickerAgGridComponent,
        AddFeedbackComponent,
        WhoReactedDialogComponent,
        FilterPipe,
        HyvesEmoji,
        CapitalizePipe,
        TruncatePipe,
        AddFeedbackComponent,
        DateTimePickerComponent,
        SpinnerComponent,
        PaginationComponent,
        ImagePickerComponent,
        FabMenuComponent,
        ConfirmDialogComponent,
        TimeSpanInputComponent,
        SearchArticleComponent,
        QuestionsDialogComponent,
        AddTagComponent,
        ReactionComponent,
        DocumentsComponent,
        MyDocumentsComponent,
        MemberCertificatesComponent,
        MemberBadgesComponent,
        QuestionsFormComponent,
        ExtraTicketsDialogComponent,
        WeatherComponent
    ],
    exports: [
        MemberCertificatesComponent,
        MemberBadgesComponent,
        DatePickerAgGridComponent,
        AgGridModule,
        DocumentsComponent,
        ToggleBtnAgRendererComponent,
        BtnIconAgRendererComponent,
        AngularIbanModule,
        ReactiveFormsModule,
        TimeSpanInputComponent,
        AddFeedbackComponent,
        MarkdownModule,
        FilterPipe,
        HyvesEmoji,
        CapitalizePipe,
        TruncatePipe,
        HyvesEmoji,
        ImageCropperModule,
        DateTimePickerComponent,
        SpinnerComponent,
        PaginationComponent,
        ImagePickerComponent,
        FabMenuComponent,
        SearchArticleComponent,
        ReactionComponent,
        /** Material shizzle */
        MatChipsModule,
        FlexLayoutModule,
        MatTableModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatTooltipModule,
        MatGridListModule,
        MatPaginatorModule,
        ExtraTicketsDialogComponent,
        MatIconModule,
        MatToolbarModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatSelectModule,
        MatSortModule,
        MatMenuModule,
        MatDividerModule,
        MatSidenavModule,
        MatListModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatListModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatBadgeModule,
        MatCheckboxModule,
        MatSliderModule,
        MatRippleModule,
        /** End of Material shizzle */
        NgxMaterialTimepickerModule,
        ConfirmDialogComponent,
        QuestionsDialogComponent,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        MatMomentDateModule,
        AddTagComponent,
        NgxMatNativeDateModule,
        MyDocumentsComponent,
        QuestionsFormComponent,
        WeatherComponent
    ]
})
export class SharedModule {}
